import React from 'react';
import PropTypes from 'prop-types';
import Editor from "../../Editor/Editor";


const Heading = ({ border, text, width, height, margin, marginLeft, elementId }) => {
  const style = {
    width: width,
    height: height,
    margin: margin,
    marginLeft: marginLeft,
  };
  return(
<>
    <Editor 
      border={border}
      menuBar={false}
      editable={false}
      text={text}
      elementId={elementId}
    />
    </>
  ) ;
  
  // <div style={style}>
  //   <Editor 
  //     menuBar={false}
  //     editable={false}
  //     text={text}
  //     elementId={elementId}
  //   />
  //   </div>;
};

Heading.propTypes = {
  text: PropTypes.string.isRequired,
  margnLeft: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  margin: PropTypes.string,
};

export default Heading;

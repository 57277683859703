import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const FileUploadProperties = ({
  label,
  width,
  height,
  onRequiredFieldChange,
  required,
  onLabelChange,
  onWidthChange,
  onHeightChange,
  elementId,
  onRemove
}) => {
  return (
    <>
     <h2>FILEUPLOAD PROPERTIES</h2>
      <TextField
        label="Label"
        value={label}
        onChange={(e) => onLabelChange(e.target.value)}
        fullWidth
        style={{ marginTop: '10px' }}
      />
      <TextField
        label="Width"
        value={width}
        onChange={(e) => onWidthChange(e.target.value)}
        style={{ marginTop: '10px' }}
      />
      <TextField
        label="Height"
        value={height}
        onChange={(e) => onHeightChange(e.target.value)}
        style={{ marginTop: '10px' }}
      />
      {/* <FormControlLabel
        control={
          <Checkbox
            checked={required}
            onChange={(e) => onRequiredFieldChange(e.target.checked)}
            color="primary"
          />
        }
        label="Required"
        style={{ marginTop: '10px' }}
      /> */}
       <Button
          variant="contained"
          color="error" 
          onClick={onRemove}
          fullWidth
          style={{marginTop: '10px'}}
        >
          Remove
      </Button>
    </>
  );
};

FileUploadProperties.propTypes = {
  label: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  style: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default FileUploadProperties;

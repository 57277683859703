import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";

const HorizontalContaierProperties = ({
  height,
  width,
  onRowHeightChange,
  onRemove,
  onRowWidthChange,
}) => {
  return (
    <Grid container spacing={2}>
      <h2>HORIZONTALCONTAINER PROPERTIES</h2>
      {/* <Grid item xs={6}>
        <TextField
          label="Height"
          value={height}
          onChange={(e) => onRowHeightChange(e.target.value)}
          fullWidth
          style={{ marginTop: '10px' }}
        />
      </Grid> */}
      <TextField
        label="Width"
        value={width}
        onChange={(e) => onRowWidthChange(e.target.value)}
        fullWidth
        style={{ marginTop: "10px" }}
      />
      <Button
        variant="contained"
        color="error"
        onClick={onRemove}
        fullWidth
        style={{ marginTop: "10px" }}
      >
        Remove
      </Button>
    </Grid>
  );
};

HorizontalContaierProperties.propTypes = {
  onRowHeightChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onRowWidthChange: PropTypes.func.isRequired,
};

export default HorizontalContaierProperties;

import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';

const LogoComponentProperties = ({ width,height, onRemove,onWidthChange, onHeightChange  }) => {
  return (
    <>
     <h2>LOGO PROPERTIES</h2>
     <TextField
        label="Width"
        value={width}
        onChange={(e) => onWidthChange(e.target.value)}
        fullWidth
        style={{marginTop: '10px'}}
      />
      <TextField
        label="Height"
        value={height}
        onChange={(e) => onHeightChange(e.target.value)}
        fullWidth
        style={{marginTop: '10px'}}
      /> 
        <Grid container spacing={2}>
       <Button
          variant="contained"
          color="error" 
          onClick={onRemove}
          fullWidth
          style={{marginTop: '10px'}}
        >
          Remove
      </Button>
    </Grid>
    </>

  );
};

LogoComponentProperties.propTypes = {
  onWidthChange: PropTypes.func.isRequired,
  onHeightChange: PropTypes.func.isRequired,
  onSelectedFieldChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default LogoComponentProperties;

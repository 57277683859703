import React from 'react';
import { CircularProgress, Backdrop } from '@mui/material';


const LoadingOverlay = ({ open }) => (
    <Backdrop open={open} s sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
  
  export default LoadingOverlay;
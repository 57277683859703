
import React from "react";
import { Grid, Box,Typography} from "@mui/material";

const SignatureFileFillElement = ({ element }) => {
    return (
        <Box>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              {element.props.label} 
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <img
              src={element.props.value}
              alt={`Image ${element.type}`}
              style={{ width: '100%', maxWidth: element.props.width, height: element.props.height,  border: '1px solid #ccc', }}
            />
          </Grid>
        </Grid>
      </Box>
    );
  };
  export default SignatureFileFillElement
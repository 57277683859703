import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Paper, Typography, Button, Modal, IconButton } from "@mui/material";
import SignatureCanvas from "react-signature-canvas";
import { useDispatch } from "react-redux";
import { updateElement } from "../../../store/formSlice";
import CloseIcon from "@mui/icons-material/Close";

const Signature = (props) => {
  const dispatch = useDispatch();
  const [trimmedDataURL, setTrimmedDataURL] = useState(null);
  const sigPadRef = useRef(null);
  const [open, setOpen] = useState(false); // State for modal open/close

  const clear = () => {
    sigPadRef.current.clear();
    if (trimmedDataURL) {
      setTrimmedDataURL(null);
    }
  };

  // const trim = () => {
  //   const trimmedCanvas = sigPadRef.current.getTrimmedCanvas(); // Access trimmed canvas
  //   setTrimmedDataURL(trimmedCanvas.toDataURL('image/png')); // Update state
  // };

  const handleSignatureChange = () => {
    const signature = sigPadRef.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    setTrimmedDataURL(signature);
    // Update the element in the form state with the signature data
    const updatedElement = {
      id: props.elementId,
      element: {
        ...props,
        value: signature,
      },
    };
    dispatch(updateElement(updatedElement));
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    setTimeout(() => {
      if (sigPadRef.current && trimmedDataURL) {
        //sigPadRef.current.fromDataURL(trimmedDataURL);
        const ctx = sigPadRef.current.getCanvas().getContext("2d");
        const img = new Image();
        img.src = trimmedDataURL;
        img.onload = () => {
          const centerX = (sigPadRef.current.getCanvas().width - img.width) / 2;
          const centerY =
            (sigPadRef.current.getCanvas().height - img.height) / 2;
          ctx.drawImage(img, centerX, centerY);
        };
      }
    }, 100);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleOpen}
        id="btnSignature"
        sx={{
          width: {
            xs: "100%",
            sm: props.width || "75%",
            md: props.width || "60%",
          },
        }}
      >
        Open Signature
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        fullScreen={{ xs: true, sm: false }}
      >
        <Paper
          sx={{
            position: "relative",
            width: { xs: "100%", sm: "75%", md: "600px" },
            height: { xs: "auto", sm: "auto", md:"400px" },
            padding: { xs: 1, sm: 2, md: 3 },
            display: "flex",
            flexDirection: "column",
          }}
        >
          <IconButton
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          {props.label && (
            <Typography variant="subtitle1" gutterBottom>
              {props.label}
            </Typography>
          )}
          <SignatureCanvas
            ref={sigPadRef}
            canvasProps={{ width: "600px", height: "400px" }}
          />
          <div style={{ marginTop: "-50px", marginLeft: "-10px"}}>
            <Button variant="contained" onClick={clear} sx={{ mr: 1 }}>
              Clear
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSignatureChange}
            >
              OK
            </Button>
          </div>
          {/* {trimmedDataURL && <img src={trimmedDataURL} alt="Signature Preview" />} */}
        </Paper>
      </Modal>
    </>
  );
};

Signature.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  label: PropTypes.string,
};

export default Signature;

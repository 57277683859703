import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Editor from "../../Editor/Editor";
import { Box } from '@mui/material';
import FullscreenToggle from '../../FullScreenToggleBtn';



const HeadingProperties = ({
  text,
  width,
  height,
  margin,
  marginLeft,
  backgroundColor,
  onTextChange,
  onBackgroundColorChange,
  onWidthChange,
  onHeightChange,
  onMarginChange,
  onMarginLeftChange,
  elementId,
  onRemove
}) => {

  return (
    <>
        <h2>HEADING PROPERTIES</h2>
      <Editor
        label="Heading"
        onTextChange={(value) => onTextChange(value)}
        fullWidth
        elementId={elementId}
        text={text}
      />
      <TextField
        label="Width"
        value={width}
        onChange={(e) => onWidthChange(e.target.value)}
        fullWidth
        style={{marginTop: '10px'}}
      />
      <TextField
        label="Height"
        value={height}
        onChange={(e) => onHeightChange(e.target.value)}
        fullWidth
        style={{marginTop: '10px'}}
      />
      <TextField
        label="Margin"
        value={margin}
        onChange={(e) => onMarginChange(e.target.value)}
        fullWidth
        style={{marginTop: '10px'}}
      />
      <TextField
        label="Margin Left"
        value={marginLeft}
        onChange={(e) => onMarginLeftChange(e.target.value)}
        fullWidth
        style={{marginTop: '10px'}}
      />
       <Button
          variant="contained"
          color="error" 
          onClick={onRemove}
          fullWidth
          style={{marginTop: '10px'}}
        >
          Remove
      </Button>
    </>
  );
};

HeadingProperties.propTypes = {
  text: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  margin: PropTypes.string.isRequired,
  marginLeft: PropTypes.string.isRequired,
  onTextChange: PropTypes.func.isRequired,
  onBackgroundColorChange: PropTypes.func.isRequired,
  onLevelChange: PropTypes.func.isRequired,
  onWidthChange: PropTypes.func.isRequired,
  onHeightChange: PropTypes.func.isRequired,
  onMarginChange: PropTypes.func.isRequired,
  onMarginLeftChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default HeadingProperties;

import { v4 as uuidv4 } from "uuid";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

export const getS3Url = async (fileName) => {
  try {
    const res = await fetch(
      `https://s5a26rvza4jr333q7wugsfkega0gurkl.lambda-url.eu-west-2.on.aws`,
      {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify({ filename: fileName }),
      }
    );
    const resultURL = await res.json();
    return resultURL;
  } catch (err) {
    console.error("Error fetching the S3 URL:", err);
  }
};

export const generatePdf = async (elementRef) => {
  const element = elementRef.current;
  const canvas = await html2canvas(element, { scale: 2 });
  const data = canvas.toDataURL("image/jpeg", 0.5);
  const pdf = new jsPDF("p", "mm", "a4");
  const imgProperties = pdf.getImageProperties(data);
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

  let position = 0;
  let remainingHeight = pdfHeight;

  while (remainingHeight > 0) {
    pdf.addImage(data, "JPEG", 0, position, pdfWidth, pdfHeight, undefined, 'FAST');
    remainingHeight -= pdf.internal.pageSize.getHeight();
    position -= pdf.internal.pageSize.getHeight();
    if (remainingHeight > 0) {
      pdf.addPage();
    }
  }

  const fileUniqueName = `hhcontractsigned_${uuidv4()}.pdf`;
  const pdfBlob = pdf.output("blob");

  return { pdfBlob, fileUniqueName };
};

export const uploadPdfToS3 = async (pdfBlob, fileName) => {
  try {
    const { uploadUrl, downloadUrl } = await getS3Url(fileName);
    const resUpload = await fetch(uploadUrl, {
      method: "PUT",
      headers: { "Content-Type": "application/pdf" },
      body: pdfBlob,
    });
    if (resUpload.ok) {
      console.log("PDF uploaded successfully!");
    } else {
      console.error("Failed to upload PDF to S3.");
    }
  } catch (error) {
    console.error("Error uploading file:", error);
  }
};

export const fetchFormData = async (clientId) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/api/v1/HHContract/GetActiveHHContractstByClientId?clientId=${clientId}`,
      {
        headers: { "Content-Type": "application/json" },
        method: "GET",
      }
    );
    if (res.ok) {
      const json = await res.json();
      console.log("Form elements fetched successfully:", json.MetaData);
      return json.MetaData;
    } else {
      console.error("Failed to fetch form elements:", await res.text());
    }
  } catch (error) {
    console.error("Error while fetching form elements:", error);
  }
};

export const fetchSubContractorDetail = async (clientId, subcontractorId) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/api/v1/Subcontractor/GetSubcontractorDetails?subcontractorId=${subcontractorId}&clientId=${clientId}`,
      {
        headers: { "Content-Type": "application/json" },
        method: "GET",
      }
    );
    if (res.ok) {
      const json = await res.json();
      console.log("Subcontractors fetched successfully:", json);
      return json;
    } else {
      console.error("Failed to fetch subcontractor details:", await res.text());
    }
  } catch (error) {
    console.error("Error while fetching subcontractor details:", error);
  }
};

export const mergeElementsWithFormValues = (elements, formElementValues) => {
  const formElementValuesMap = new Map(formElementValues.map(item => [item.elementId, item]));
  return elements.map(element => {
    const updatedElement = formElementValuesMap.get(element.id);
    if (updatedElement) {
      element.props.value = updatedElement.value;
    }
    return element;
  });
};

export const fetchSubcontractorTypeData = async (clientId) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/api/v1/Subcontractor/GetSubcontractorType?clientId=${clientId}`,
      {
        headers: { "Content-Type": "application/json" },
        method: "GET",
      }
    );
    if (res.ok) {
      const result=await res.json();
      return  result;
    } else {
      console.error("Failed to fetch form elements:", await res.text());
    }
  } catch (error) {
    console.error("Error while fetching form elements:", error);
  }
};


export const fetchClientDetailData = async (clientId) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/api/v1/client/GetClientDetailById?clientId=${clientId}`,
      {
        headers: { "Content-Type": "application/json" },
        method: "GET",
      }
    );
    if (res.ok) {
      const result=await res.json();
      return  result;
    } else {
      console.error("Failed to fetch form elements:", await res.text());
    }
  } catch (error) {
    console.error("Error while fetching form elements:", error);
  }
};

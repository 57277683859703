import { Lock, LockOpen, TextFields, Fullscreen, FullscreenExit } from "@mui/icons-material";
import { Box, Button, Stack } from "@mui/material";
import { useCallback, useState, useEffect, useRef } from "react";
import {
  LinkBubbleMenu,
  MenuButton,
  RichTextEditor,
  insertImages,
  TableBubbleMenu,
} from "mui-tiptap";
import EditorMenuControls from "./EditorMenuControls";
import useExtensions from "./useExtensions";

function fileListToImageFiles(fileList) {
  return Array.from(fileList).filter((file) => {
    const mimeType = (file.type || "").toLowerCase();
    return mimeType.startsWith("image/");
  });
}

export default function Editor({ border = false, menuBar = true, editable = true, text, onTextChange, elementId, backgroundColor }) {
  const rteRef = useRef(elementId);
  const editorContainerRef = useRef(null);
  const extensions = useExtensions({
    placeholder: "Add your own content here...",
  });
  const [isEditable, setIsEditable] = useState(editable);
  const [showMenuBar, setShowMenuBar] = useState(menuBar);
  const [submittedContent, setSubmittedContent] = useState(text);
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    if (rteRef?.current?.editor?.commandManager?.commands?.setContent) {
      rteRef.current.editor.commandManager.commands.setContent(text);
    }
  }, [text, elementId]);

  useEffect(() => {
    if (onTextChange) {
      onTextChange(submittedContent);
    }
  }, [submittedContent]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      const nonEditorElements = document.querySelectorAll('.non-fullscreen');
      if (isFullscreen) {
        nonEditorElements.forEach(el => el.classList.add('hidden'));
      } else {
        nonEditorElements.forEach(el => el.classList.remove('hidden'));
      }
    };

    handleFullscreenChange(); // Apply styles on mount
    return () => {
      document.querySelectorAll('.hidden').forEach(el => el.classList.remove('hidden')); // Cleanup on unmount
    };
  }, [isFullscreen]);

  const handleNewImageFiles = useCallback(
    (files, insertPosition) => {
      if (!rteRef.current?.editor) {
        return;
      }

      const attributesForImageFiles = files.map((file) => ({
        src: URL.createObjectURL(file),
        alt: file.name,
      }));

      insertImages({
        images: attributesForImageFiles,
        editor: rteRef.current.editor,
        insertPosition,
      });
    },
    [],
  );

  const handleDrop = useCallback(
    (view, event, _slice, _moved) => {
      if (!(event instanceof DragEvent) || !event.dataTransfer) {
        return false;
      }

      const imageFiles = fileListToImageFiles(event.dataTransfer.files);
      if (imageFiles.length > 0) {
        const insertPosition = view.posAtCoords({
          left: event.clientX,
          top: event.clientY,
        })?.pos;

        handleNewImageFiles(imageFiles, insertPosition);

        event.preventDefault();
        return true;
      }

      return false;
    },
    [handleNewImageFiles],
  );

  const handlePaste = useCallback(
    (_view, event, _slice) => {
      if (!event.clipboardData) {
        return false;
      }

      const pastedImageFiles = fileListToImageFiles(
        event.clipboardData.files,
      );
      if (pastedImageFiles.length > 0) {
        handleNewImageFiles(pastedImageFiles);
        return true;
      }

      return false;
    },
    [handleNewImageFiles],
  );

  const handleFullscreenToggle = () => {
    setIsFullscreen((prev) => !prev);
  };

  return (
    <>
      <Box
        ref={editorContainerRef}
        className={isFullscreen ? 'editor-fullscreen' : 'non-fullscreen'}
        sx={{
          backgroundColor: backgroundColor || '#ffffff',
          "& .ProseMirror": {
            "& h1, & h2, & h3, & h4, & h5, & h6": {
              scrollMarginTop: showMenuBar ? { xs: 40, sm: 50, md: 60 } : 0,
            },
          },
        }}
      >
        <RichTextEditor
          ref={rteRef}
          extensions={extensions}
          content={text}
          editable={isEditable}
          editorProps={{
            handleDrop: handleDrop,
            handlePaste: handlePaste,
          }}
          renderControls={(editor) => <EditorMenuControls editor={editor} />}
          RichTextFieldProps={{
            variant: border ? "outlined" : "standard", //outlined is for Bordered UI and standard is for without border and outlined
            MenuBarProps: {
              hide: !showMenuBar,
            },
            footer: showMenuBar ? (
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  borderTopStyle: "solid",
                  borderTopWidth: 1,
                  borderTopColor: (theme) => theme.palette.divider,
                  py: { xs: 0.5, sm: 1, md: 1.5 },
                  px: { xs: 1, sm: 1.5, md: 2 },
                }}
              >
                <MenuButton
                  value="formatting"
                  tooltipLabel={
                    showMenuBar ? "Hide formatting" : "Show formatting"
                  }
                  size="small"
                  onClick={() =>
                    setShowMenuBar((currentState) => !currentState)
                  }
                  selected={showMenuBar}
                  IconComponent={TextFields}
                />

                <MenuButton
                  value="formatting"
                  tooltipLabel={
                    isEditable
                      ? "Prevent edits (use read-only mode)"
                      : "Allow edits"
                  }
                  size="small"
                  onClick={() =>
                    setIsEditable((currentState) => !currentState)
                  }
                  selected={!isEditable}
                  IconComponent={isEditable ? Lock : LockOpen}
                />

                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setSubmittedContent(
                      rteRef.current?.editor?.getHTML() ?? "",
                    );
                  }}
                >
                  Save
                </Button>

                <Button
                  variant="contained"
                  size="small"
                  onClick={handleFullscreenToggle}
                >
                  {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
                </Button>
              </Stack>
            ) : null,
          }}
        >
          {() => (
            <>
              <LinkBubbleMenu />
              <TableBubbleMenu />
            </>
          )}
        </RichTextEditor>
      </Box>
    </>
  );
}

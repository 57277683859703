import { v4 as uuidv4 } from "uuid";
import { fetchClientDetailData } from "../../utils/formUtils";
import { useParams } from "react-router-dom";
const elementTypes = [
  {
    id: uuidv4(),
    type: "logo",
    props: { label: "logo", width: "100px", height: "80px"},
  },
    {
      id: uuidv4(),
      type: "heading",
      props: {
        text: "Heading",
        level: "2",
        color: "blue",
        backgroundColor: "yellow",
        height: "40px",
        width: "400px",
        margin: "2px",
      },
    },
    {
      id: uuidv4(),
      type: "textField",
      props: {
        label: "Text Field",
        color: "primary",
        width: "200px",
        height: "50px",
      },
    },
    {
      id: uuidv4(),
      type: "paragraph",
      props: {
        text: "Paragraph",
        level: "2",
        color: "blue",
        backgroundColor: "#ffffff",
        height: "40px",
        width: "400px",
        padding: "2px",
        fontSize: "12px",
      },
    },
    {
      id: uuidv4(),
      type: "signature",
      props: { label: "Signature", width: "600px", height: "400px" },
    },
    {
      id: uuidv4(),
      type: "radioSelection",
      props: {
        label: "Radio",
        width: "600px",
        height: "200px",
        options: ["1", "2"],
      },
    },
    {
      id: uuidv4(),
      type: "fileUpload",
      props: { label: "FileName", width: "600px", height: "250px" },
    },
    {
      id: uuidv4(),
      type: "datePicker",
      props: { label: "Date", width: "600px", height: "250px" },
    },
    {
      id: uuidv4(),
      type: "checkBox",
      props: { label: "Checkbox", width: "25px", height: "25px" },
    },
    {
      id: uuidv4(),
      type: "horizontalContainer",
      props: {
        label: "Horizotal Container",
        height: "200px",
        width:"500px"
      },
    },
  ];

  export default elementTypes;
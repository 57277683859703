// Paragraph.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { useEffect } from "react";
import Editor from "../../Editor/Editor";




const Paragraph = ({ border, text, color, backgroundColor, fontSize, padding, height, width, elementId }) => {

  const style = {
    color: color,
    backgroundColor: backgroundColor,
    fontSize: fontSize,
    padding: padding,
    height: height,
    width: width,
  };
  return (
    <>
    <Editor 
      border={border}
      menuBar={false}
      editable={false}
      text={text}
      elementId={elementId}
      backgroundColor={backgroundColor}
    />
    </>
    )
    
};

Paragraph.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  fontSize: PropTypes.string,
  padding: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default Paragraph;

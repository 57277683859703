// src/context/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    Token: localStorage.getItem('Token'),
    Role: localStorage.getItem('Role'),
    UserId: localStorage.getItem('UserId'),
    TokenExpiry: localStorage.getItem('TokenExpiry'),
    Name: localStorage.getItem('Name'),
    Email: localStorage.getItem('Email'),
  });

  const setAuthData = ({ Token, Role, UserId, TokenExpiry, Name, Email }) => {
    localStorage.setItem('Token', Token);
    localStorage.setItem('Role', Role);
    localStorage.setItem('UserId', UserId);
    localStorage.setItem('TokenExpiry', TokenExpiry);
    localStorage.setItem('Name', Name);
    localStorage.setItem('Email', Email);
    setAuth({ Token, Role, UserId, TokenExpiry, Name, Email });
  };

  const clearAuthData = () => {
    localStorage.removeItem('Token');
    localStorage.removeItem('Role');
    localStorage.removeItem('UserId');
    localStorage.removeItem('TokenExpiry');
    localStorage.removeItem('Name');
    localStorage.removeItem('Email');
    setAuth({ Token: null, Role: null, UserId: null, TokenExpiry: null, Name: null, Email: null });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (auth.Token && new Date().getTime() > auth.TokenExpiry) {
        clearAuthData();
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [auth]);

  return (
    <AuthContext.Provider value={{ auth, setAuthData, clearAuthData }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };

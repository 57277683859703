import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const SortableElement = ({ id, index, children, setSelectedElementId, dragHandle }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
    position: "relative", // Ensures the handle stays inside the draggable area
  };

  const handleClick = (event) => {
    event.stopPropagation(); // Prevent the click event from propagating to parent elements
    setSelectedElementId(id);
  }

  return dragHandle === false ?  (
    <div ref={setNodeRef} style={style}>
      <div>{children}</div>
    </div>
  ) :
  (
    <div ref={setNodeRef} style={style}>
      {/* Drag handle */}
      <div
        {...attributes}
        {...listeners}
        style={{
          position: "absolute",
          top: "10%",
          left: "0%",
          transform: "translateX(-50%)",
          width: "20px",
          height: "100px",
          cursor: "grab",
          display: "block",
        }}
      >
        {" "}⣿
      </div>
      <div onClick={handleClick}>{children}</div>
    </div>
  )
};

export default SortableElement;

// SignatureProperties.js
import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


const SignatureProperties = ({ label, width, height, onRequiredFieldChange, required, onWidthChange, onHeightChange, onLabelChange, onRemove }) => {
  return (
    <>
     <h2>SIGNATURE PROPERTIES</h2>
      <TextField
        label="Label"
        value={label}
        onChange={(e) => onLabelChange(e.target.value)}
        fullWidth
        style={{marginTop: '10px'}}
      />
      <TextField
        label="Width"
        value={width}
        onChange={(e) => onWidthChange(e.target.value)}
        fullWidth
        style={{marginTop: '10px'}}
      />
      <TextField
        label="Height"
        value={height}
        onChange={(e) => onHeightChange(e.target.value)}
        fullWidth
        style={{marginTop: '10px'}}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={required}
            onChange={(e) => onRequiredFieldChange(e.target.checked)}
            color="primary"
          />
        }
        label="Required"
        style={{ marginTop: '10px' }}
      />
       <Button
          variant="contained"
          color="error" 
          onClick={onRemove}
          fullWidth
          style={{marginTop: '10px'}}
        >
          Remove
      </Button>
    </>
  );
};

SignatureProperties.propTypes = {
  label: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  onWidthChange: PropTypes.func.isRequired,
  onHeightChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default SignatureProperties;

import { Node } from '@tiptap/core';

export const BackgroundColor = Node.create({
  name: 'backgroundColor',

  addOptions() {
    return {
      defaultColor: null,
    };
  },

  group: 'block',
  content: 'block+',
  defining: true,

  addAttributes() {
    return {
      backgroundColor: {
        default: this.options.defaultColor,
        parseHTML: element => element.style.backgroundColor || this.options.defaultColor,
        renderHTML: attributes => {
          if (!attributes.backgroundColor) {
            return {};
          }
          return { style: `background-color: ${attributes.backgroundColor}` };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div',
        getAttrs: node => node.style.backgroundColor && null,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', HTMLAttributes, 0];
  },

  addCommands() {
    return {
      setBackgroundColor: color => ({ commands }) => {
        return commands.wrapIn(this.name, { backgroundColor: color });
      },
      unsetBackgroundColor: () => ({ commands }) => {
        return commands.lift(this.name);
      },
    };
  },
});

import { useParams, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Grid, Button, Paper, Box,Typography,FormControl, FormLabel, RadioGroup, Radio, FormControlLabel,Checkbox } from "@mui/material";



const CheckboxFillElement=({element })=>{
    const [checked, setChecked] = useState(element.props.value);
    const handleChange = (event) => {
        setChecked(event.target.checked);
     };
   console.log(element,'element')
     return (
        <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleChange} />}
        label={element.props.label}
      />
     );
   }

    
export default CheckboxFillElement;
import { Heading } from '@tiptap/extension-heading';

const HHHeading = Heading.extend({
  name: 'hhHeading',
  priority: 1000,
  addAttributes() {
    return {
      ...this.parent?.(),
      class: {
        default: null, // No default class
        parseHTML: element => element.className.split(' ').filter(className => className !== 'hh-heading'), // Filter out 'hh-heading' class
        renderHTML: attributes => {
          const existingClasses = attributes.class ? attributes.class : [];
          // Check if 'hh-heading' already exists, avoid duplicates
          if (!existingClasses.includes('hh-heading')) {
            existingClasses.push('hh-heading');
          }
          return {
            class: existingClasses.join(' '),
          };
        },
      },
    };
  },
});

export default HHHeading;

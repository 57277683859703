export const findElementIndex = (elements, id) => {
  for (let i = 0; i < elements.length; i++) {
    if (elements[i].id === id) {
      return i;
    }
    if (elements[i].items) {
      const index = findElementIndex(elements[i].items, id);
      if (index !== -1) {
        return index;
      }
    }
  }
  return -1;
};

export const findElementById = (elements, id) => {
  for (let i = 0; i < elements.length; i++) {
    if (elements[i].id === id) {
      return elements[i];
    }
    if (elements[i].items) {
      const found = elements[i].items.find(el => el.id === id)
      if (found) {
        return found;
      }
    }
  }
  return null;
};

export const removeElement = (elements, id) => {
  for (let i = 0; i < elements.length; i++) {
    if (elements[i].id === id) {
      return elements.splice(i, 1)[0];
    }
    if (elements[i].items) {
      const nestedElement = removeElement(elements[i].items, id);
      if (nestedElement) {
        return nestedElement;
      }
    }
  }
  return null;
};

export const findRootElement = (elements, id) => {
  for (let i = 0; i < elements.length; i++) {
    if (elements[i].id === id) {
      return elements[i];
    } else if (elements[i].items){
      const found = elements[i].items.find(el => el.id === id);
      if (found){
        return elements[i];
      }
    }
  }
  return null;
};
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Paper, Box } from "@mui/material";
import { SortableContext, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import FormElement from "../../FormBuilder/FormElement";
import SortableElement from "../../FormBuilder/SortableElement";


const HorizotalContaier = ({ items = [], dragHandle, setSelectedElementId,width }) => {
  return (
    <div className="horizontal-contaier" style={{ display: 'flex', minHeight: '50px',width:width }}>
      <SortableContext
        items={items}
        strategy={horizontalListSortingStrategy}
      >
             {items.map((item, index) => (
                  <Grid xs={12} item key={item.id}  sx={{
                    // ...(item.type === 'signature' && { marginRight: '200px' }), // Adjust the margin value as needed
                  }}>
                    <SortableElement
                      setSelectedElementId={setSelectedElementId}
                      handle={true}
                      key={item.id}
                      id={item.id}
                      index={index}
                      element={item}
                      dragHandle={dragHandle}
                    >
                      <FormElement element={item} />
                    </SortableElement>
                  </Grid>
                ))}

      </SortableContext>
    </div>
  );
};

HorizotalContaier.propTypes = {
  initialItems: PropTypes.arrayOf(PropTypes.element),
  width: PropTypes.string,
};

export default HorizotalContaier;

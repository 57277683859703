import React, { useState, useEffect } from "react";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  FormControl,
  InputLabel,
  Autocomplete,
  useMediaQuery,
  useTheme,
  Badge,
  Link,
  Tooltip,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  UpdateContractActive,
  UpdateContractVersion,
  CopyHHContract,
  FetchData,
} from "../api/contractVersionService";
import toast from "react-hot-toast";
import Download from "@mui/icons-material/Download";
import ConfirmationDialog from "../confirm/confirmation";
import { ACTION } from "../utils/commonConstant";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { DataGrid } from "@mui/x-data-grid";
import "../App.css";

const ContractVersion = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editData, setEditData] = useState({
    VersionName: "",
    HHApprovalStatus: "",
    InsurerApprovalStatus: "",
    COApprovalStatus: "",
    IsActive: false,
  });
  const { clientId } = useParams();
  const navigate = useNavigate();
  const approvalStatus = ["Pending", "Approved"];

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [rowToConfirm, setRowToConfirm] = useState(null);
  const [confirmHHDialogOpen, setConfirmHHDialogOpen] = useState(false);
  const [confirmIsActiveDialogOpen, setConfirmIActiveDialogOpen] =
    useState(false);
  const [confirmCOApprovalDialogOpen, setConfirmCOApprovalialogOpen] =
    useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
  });
  const [rows, setRows] = useState([]);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      headerClassName: "bold-header",
    },
    {
      field: "VersionName",
      headerName: "Version Name",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-header",
      renderCell: (params) => (
        <Link
          href={`/${clientId}/versions/${ACTION.EDIT}/${params.row.Id}`}
          underline="hover"
          sx={{
            color: "text.primary",
            "&:hover": {
              color: "primary.main",
            },
          }}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "SubcontractorType",
      headerName: "Subcontractor Type",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-header",
    },
    {
      field: "HHApprovalStatus",
      headerName: "HH Approval",
      width: 150,
      align: "center",
      headerClassName: "bold-header",
      headerAlign: "center",
      renderCell: (params) => (
        <IconButton onClick={() => handleToggleHHApproval(params.row)}>
          <Badge
            color={getBadgeColor(params.row.HHApprovalStatus)}
            badgeContent={params.row.HHApprovalStatus}
          />
        </IconButton>
      ),
    },
    {
      field: "InsurerApprovalStatus",
      headerName: "Insurer Approval",
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-header",
      width: 150,
      renderCell: (params) => (
        <IconButton onClick={() => handleToggleInsurerApproval(params.row)}>
          <Badge
            color={getBadgeColor(params.row.InsurerApprovalStatus)}
            badgeContent={params.row.InsurerApprovalStatus}
          />
        </IconButton>
      ),
    },
    {
      field: "COApprovalStatus",
      headerName: "CO Approval",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-header",
      renderCell: (params) => (
        <IconButton onClick={() => handleToggleCOApproval(params.row)}>
          <Badge
            color={getBadgeColor(params.row.COApprovalStatus)}
            badgeContent={params.row.COApprovalStatus}
          />
        </IconButton>
      ),
    },
    {
      field: "IsActive",
      headerName: "IsActive",
      width: 150,
      align: "center",
      headerClassName: "bold-header",
      headerAlign: "center",
      renderCell: (params) => (
        <IconButton onClick={() => handleIsActiveToggle(params.row)}>
          {params.row.IsActive ? (
            <CheckIcon color="primary" />
          ) : (
            <CloseIcon color="error" />
          )}
        </IconButton>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      sortable: false,
      align: "center",
      headerClassName: "bold-header",
      headerAlign: "center",
      renderCell: (params) => (
        <Box>
          <Tooltip title="Download Pdf" arrow>
            <IconButton
              color="success"
              aria-label="Download PDF"
              onClick={() => handleDownloadContract(params.row)}
            >
              <Download />
            </IconButton>
          </Tooltip>
          <Tooltip title="Copy URL" arrow>
            <IconButton
              color="info"
              aria-label="Copy URL"
              onClick={() => handleCopyUrl(params.row)}
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit/Copy" arrow>
            <IconButton onClick={(event) => handleMenuOpen(event, params.row)}>
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleEdit}>Edit Version</MenuItem>
            <MenuItem onClick={handleCopyContract}>Copy Contract</MenuItem>
          </Menu>
        </Box>
      ),
    },
  ];
  const fetchData = async () => {
    try {
      const result = await FetchData(clientId);
      setData(result);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };
  const addIdToData = (data) => {
    // Create a new array with 'id' field added to each object
    return data.map((item) => ({ ...item, id: item.Id }));
  };

  useEffect(() => {
    fetchData();
  }, [clientId]);

  
  useEffect(() => {
    const updatedRows = addIdToData(data);
    setRows(updatedRows);
  }, [data]);

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography color="error">
          Error fetching data: {error.message}
        </Typography>
      </Container>
    );
  }

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleEdit = () => {
    setEditData(selectedRow);
    setEditDialogOpen(true);
    handleMenuClose();
  };

  const handleActivateContract = async () => {
    console.log("Update Status:", rowToConfirm);
    const resp = await UpdateContractActive(rowToConfirm.Id);
    console.log("handleActivateContract :", resp);
    handleMenuClose();
    fetchData(); // Refresh data after update
    toast.success("Contract is activated successfully !!!");
    setConfirmIActiveDialogOpen(false);
  };
  const handleIsActiveToggle = async (row) => {
    setConfirmIActiveDialogOpen(true);
    setRowToConfirm(row);
  };
  const handleCopyContract = async () => {
    console.log("Update Status:", selectedRow);
    const resp = await CopyHHContract(selectedRow.Id);
    console.log("handleActivateContract :", resp);
    handleMenuClose();
    fetchData(); // Refresh data after update
    toast.success("Contract is copied successfully !!!");
  };

  // const handleBack = () => {
  //   window.history.back();
  // };

  const handleCreate = () => {
    navigate(`/${clientId}/versions/${ACTION.CREATE}`);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditData({ ...editData, [name]: value });
  };

  const handleAutocompleteChange = (event, newValue, fieldName) => {
    setEditData({ ...editData, [fieldName]: newValue });
  };

  const handleEditSave = async () => {
    try {
      console.log("Save Edit Data:", editData);
      const updatedContract = await UpdateContractVersion(
        editData.Id,
        editData
      );
      console.log("Save Edit:", updatedContract);
      fetchData(); // Refresh data after update
      // Optionally update state or handle success message
      toast.success("Updated successfully !!!");
    } catch (error) {
      // Handle error
    }
    setEditDialogOpen(false);
  };
  const handleDownloadContract = (row) => {
    const currentUrl = window.location.href;
    const pdfDownloadUrl = `${process.env.REACT_APP_HHMANAGER_AWS_LAMBDA_PDF_URL}/?url=${currentUrl}/download/${row.Id}`;
    console.log(pdfDownloadUrl, "pdfDownloadUrl");
    window.open(pdfDownloadUrl, "_blank");
  };

  const handleCopyUrl = (row) => {
    const currentUrl = window.location.href;
    const textToCopy = `${currentUrl}/download/${row.Id}`;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.success(`URL copied to clipboard!!!`);
      })
      .catch((err) => {
        toast.error("Failed to copy URL: ", err);
      });
  };
  // const handleToggleHHApproval = async (row) => {
  //   const updatedStatus = row.HHApprovalStatus === 'Approved' ? 'Pending' : 'Approved';
  //   const updatedData = {
  //     ...row,
  //     HHApprovalStatus: updatedStatus
  //   };
  //   try {
  //     await UpdateContractVersion(row.Id, updatedData); // Assuming this function handles the API call to update the contract
  //     fetchData(); // Refresh data after update
  //     toast.success(`HH Approval Status updated to ${updatedStatus}`);
  //   } catch (error) {
  //     toast.error("Error updating HH Approval Status");
  //   }
  // };
  const handleToggleHHApproval = (row) => {
    setRowToConfirm(row);
    setConfirmHHDialogOpen(true);
  };
  const confirmToggleHHApproval = async () => {
    const updatedStatus =
      rowToConfirm.HHApprovalStatus === "Approved" ? "Pending" : "Approved";
    const updatedData = {
      ...rowToConfirm,
      HHApprovalStatus: updatedStatus,
    };
    try {
      await UpdateContractVersion(rowToConfirm.Id, updatedData); // Assuming this function handles the API call to update the contract
      fetchData(); // Refresh data after update
      toast.success(`HH Approval Status updated to ${updatedStatus}`);
    } catch (error) {
      toast.error("Error updating HH Approval Status");
    }
    setConfirmHHDialogOpen(false);
    setRowToConfirm(null);
  };
  // const handleToggleInsurerApproval = async () => {
  //   const updatedStatus = rowToConfirm.InsurerApprovalStatus === 'Approved' ? 'Pending' : 'Approved';
  //   const updatedData = {
  //     ...rowToConfirm,
  //     InsurerApprovalStatus: updatedStatus
  //   };
  //   try {
  //     await UpdateContractVersion(rowToConfirm.Id, updatedData); // Assuming this function handles the API call to update the contract
  //     fetchData(); // Refresh data after update
  //     toast.success(`Insurer Approval Status updated to ${updatedStatus}`);
  //   } catch (error) {
  //     toast.error("Error updating Insurer Approval Status");
  //   }
  //   setConfirmDialogOpen(false);
  //   setRowToConfirm(null);
  // };
  const handleToggleInsurerApproval = (row) => {
    setRowToConfirm(row);
    setConfirmDialogOpen(true);
  };

  const handleToggleCOApproval = (row) => {
    setRowToConfirm(row);
    setConfirmCOApprovalialogOpen(true);
  };
  const confirmToggleCOApproval = async () => {
    const updatedStatus =
      rowToConfirm.COApprovalStatus === "Approved" ? "Pending" : "Approved";
    const updatedData = {
      ...rowToConfirm,
      COApprovalStatus: updatedStatus,
    };
    try {
      await UpdateContractVersion(rowToConfirm.Id, updatedData); // Assuming this function handles the API call to update the contract
      fetchData(); // Refresh data after update
      toast.success(`CO Approval Status updated to ${updatedStatus}`);
    } catch (error) {
      toast.error("Error updating HH Approval Status");
    }
    setConfirmCOApprovalialogOpen(false);
    setRowToConfirm(null);
  };
  const confirmToggleInsurerApproval = async () => {
    const updatedStatus =
      rowToConfirm.InsurerApprovalStatus === "Approved"
        ? "Pending"
        : "Approved";
    const updatedData = {
      ...rowToConfirm,
      InsurerApprovalStatus: updatedStatus,
    };
    try {
      await UpdateContractVersion(rowToConfirm.Id, updatedData);
      fetchData(); // Refresh data after update
      toast.success(`Insurer Approval Status updated to ${updatedStatus}`);
    } catch (error) {
      toast.error("Error updating Insurer Approval Status");
    }
    setConfirmDialogOpen(false);
    setRowToConfirm(null);
  };
  const handleCloseConfirmationDialog = () => {
    setConfirmDialogOpen(false);
    setRowToConfirm(null);
  };

  const getBadgeColor = (status) => {
    switch (status) {
      case "Approved":
        return "success";
      case "Pending":
        return "warning";
      case "Rejected":
        return "error";
      default:
        return "default";
    }
  };
  return (
    <>
      <Box sx={{ padding: 2 }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ marginBottom: 2 }}
        >
          <Grid item xs={12} sm="auto" display="flex" justifyContent="center">
            <Link href={`/${clientId}`}>
              <img
                src="https://portal.hardhats.co.uk/img/Hardhats-Logo.png"
                alt="HardHats Logo"
                style={{ width: "80px", marginRight: "20px" }}
              />
            </Link>
          </Grid>
          <Grid item xs={12} sm="auto">
            <h2 style={{ textAlign: "center" }}>Contract for Services</h2>
          </Grid>
        </Grid>
        <Container>
          <Grid
            container
            //justifyContent="space-between"
            justifyContent="flex-end"
            alignItems="center"
            mb={2}
          >
            {/* <Grid item>
              <Button variant="contained" color="primary" onClick={handleBack}>
                Back
              </Button>
            </Grid> */}
            <Grid item justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={handleCreate}
              >
                Create New
              </Button>
            </Grid>
          </Grid>
          <Paper>
            <Box sx={{ height: 500, width: "100%" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10, 50, 100]}
                disableSelectionOnClick // Disable row selection
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
              />
            </Box>
          </Paper>
          {/* <TableContainer component={Paper}>
            <Typography variant="h5" display="flex" justifyContent="center">
              List of Contract Versions
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <Typography variant="subtitle1">Version Name</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="subtitle1">Subcontractor Type</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="subtitle1">HH Approval</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="subtitle1">
                      Insurer Approval
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="subtitle1">CO Approval</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="subtitle1">IsActive</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="subtitle1">Actions</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <TableRow key={row.Id}>
                    <TableCell align="center">
                      <Link
                        href={`/${clientId}/versions/${ACTION.EDIT}/${row.Id}`}
                        underline="hover"
                        sx={{
                          color: "text.primary",
                          "&:hover": {
                            color: "primary.main",
                          },
                        }}
                      >
                        {row.VersionName}
                      </Link>
                    </TableCell>
                    <TableCell align="center">
                     {row.SubcontractorType}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton onClick={() => handleToggleHHApproval(row)}>
                        <Badge
                          color={getBadgeColor(row.HHApprovalStatus)}
                          badgeContent={row.HHApprovalStatus}
                        />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={() => handleToggleInsurerApproval(row)}
                      >
                        <Badge
                          color={getBadgeColor(row.InsurerApprovalStatus)}
                          badgeContent={row.InsurerApprovalStatus}
                        />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      <IconButton onClick={() => handleToggleCOApproval(row)}>
                        <Badge
                          color={getBadgeColor(row.COApprovalStatus)}
                          badgeContent={row.COApprovalStatus}
                        />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      <IconButton onClick={() => handleIsActiveToggle(row)}>
                        {row.IsActive ? (
                          <CheckIcon color="primary" />
                        ) : (
                          <CloseIcon color="error" />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="Download Pdf" arrow>
                        <IconButton
                          color="success"
                          aria-label="Download PDF"
                          onClick={() => handleDownloadContract(row)}
                        >
                          <Download />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Copy URL" arrow>
                        <IconButton
                          color="info"
                          aria-label="Copy URL"
                          onClick={() => handleCopyUrl(row)}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit/Copy" arrow>
                        <IconButton
                          onClick={(event) => handleMenuOpen(event, row)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                      >
                        <MenuItem onClick={handleEdit}>Edit Version</MenuItem>
                        <MenuItem onClick={handleCopyContract}>
                          Copy Contract
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer> */}
        </Container>
      </Box>
      {/* model popup for edit */}
      <Dialog
        open={editDialogOpen}
        onClose={handleEditDialogClose}
        maxWidth="md"
        fullWidth={fullScreen}
      >
        <DialogTitle>Edit Contract Version</DialogTitle>
        <DialogContent sx={{ width: fullScreen ? "100%" : "600px" }}>
          <TextField
            margin="dense"
            name="VersionName"
            label="Version Name"
            type="text"
            fullWidth
            value={editData.VersionName}
            onChange={handleEditChange}
          />
          <Autocomplete
            options={approvalStatus}
            readOnly
            value={editData.HHApprovalStatus}
            onChange={(event, newValue) =>
              handleAutocompleteChange(event, newValue, "HHApprovalStatus")
            }
            renderInput={(params) => (
              <TextField
                {...params}
                readOnly
                label="HH Approval Status"
                margin="dense"
                fullWidth
              />
            )}
          />
          <Autocomplete
            options={approvalStatus}
            readOnly
            value={editData.InsurerApprovalStatus}
            onChange={(event, newValue) =>
              handleAutocompleteChange(event, newValue, "InsurerApprovalStatus")
            }
            renderInput={(params) => (
              <TextField
                {...params}
                readOnly
                label="Insurer Approval Status"
                margin="dense"
                fullWidth
              />
            )}
          />
          <Autocomplete
            options={approvalStatus}
            readOnly
            value={editData.COApprovalStatus}
            onChange={(event, newValue) =>
              handleAutocompleteChange(event, newValue, "COApprovalStatus")
            }
            renderInput={(params) => (
              <TextField
                {...params}
                readOnly
                label="CO Approval Status"
                margin="dense"
                fullWidth
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEditSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {/* confirmation for Insurer Approval  */}
      <ConfirmationDialog
        open={confirmDialogOpen}
        onClose={handleCloseConfirmationDialog}
        onConfirm={confirmToggleInsurerApproval}
        message={`Are you sure you want to toggle Insurer Approval Status for ${
          rowToConfirm ? rowToConfirm.VersionName : ""
        }?`}
      />
      {/* confirmation for HH Approval  */}
      <ConfirmationDialog
        open={confirmHHDialogOpen}
        onClose={() => setConfirmHHDialogOpen(false)}
        onConfirm={confirmToggleHHApproval}
        message={`Are you sure you want to toggle HH Approval Status for ${rowToConfirm?.VersionName}?`}
      />
      {/* confirmation for Activate contract  */}

      <ConfirmationDialog
        open={confirmIsActiveDialogOpen}
        onClose={() => setConfirmIActiveDialogOpen(false)}
        onConfirm={handleActivateContract}
        message={`Are you sure you want to Activate contract for ${rowToConfirm?.VersionName}?`}
      />

      {/* confirmation for   */}
      <ConfirmationDialog
        open={confirmCOApprovalDialogOpen}
        onClose={() => setConfirmCOApprovalialogOpen(false)}
        onConfirm={confirmToggleCOApproval}
        message={`Are you sure you want to toggle CO Approval Status for ${rowToConfirm?.VersionName}?`}
      />
    </>
  );
};

export default ContractVersion;

// src/pages/LoginPage.js
import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const LoginPage = () => {
//   const { setAuth } = useContext(AuthContext);
//   const navigate = useNavigate();

  const initiateLogin = () => {
    // Initiate the login flow
    const currentUrl = encodeURIComponent(window.location.origin);
    const baseUrl = window.location.origin;
    const redirectUrl = `${baseUrl}/auth-callback?redirect=${currentUrl}`;
    window.location.href = `${process.env.REACT_APP_BASE_API_URL}/Auth/HHPortal?hhredirect=${encodeURIComponent(redirectUrl)}`;
  };

  return (
    <Container maxWidth="sm">
      <Box mt={5} textAlign="center">
        <Typography variant="h4" gutterBottom>
          Welcome to HHContracts
        </Typography>
        <Typography variant="h6" gutterBottom>
          Please log in to continue
        </Typography>
        <Button variant="contained" color="primary" onClick={initiateLogin}>
          Log In
        </Button>
      </Box>
    </Container>
  );
};

export default LoginPage;

// store/formSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  formElements: [],
};

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    updateElement(state, action) {
      const updatedElements = [...state.formElements];
      const index = updatedElements.findIndex((el) => el.elementId === action.payload.id);
      if (index === -1){
        updatedElements.push(action.payload.element)
      } else {
        updatedElements[index] = action.payload.element;
      }
      return { ...state, formElements: updatedElements };
    },
  },
});

export const formElements = (state) => state.form.formElements;
export const { updateElement } = formSlice.actions;
export default formSlice.reducer;

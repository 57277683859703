import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useDispatch } from 'react-redux';
import { updateElement } from '../../../store/formSlice';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

const DatePickerComponent = ({ elementId, label, value, required }) => {
  const dispatch = useDispatch();

  const handleChange = (newValue) => {
    if (newValue && newValue.isValid()) {
      const updatedElement = {
        id: elementId,
        element: {
          label,
          value: newValue.toISOString(),
        },
      };

      dispatch(updateElement(updatedElement));
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']}>
          <DatePicker
            label={label}
            value={value ? dayjs(value) : null} // Ensure the value is a dayjs object
            onChange={handleChange}
            fullWidth
            inputFormat="DD/MM/YYYY"
            renderInput={(params) => <TextField {...params} />}
          />
        </DemoContainer>
      </LocalizationProvider>
    </Box>
  );
};

DatePickerComponent.propTypes = {
  elementId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired, // Expecting ISO string for date
};

export default DatePickerComponent;

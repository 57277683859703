// TextFieldProperties.jsx
import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


const TextFieldProperties = ({ label,
                               color,
                               width, 
                               height, 
                               selectedField, 
                               onLabelChange, 
                               onColorChange, 
                               onWidthChange, 
                               onHeightChange, 
                               onSelectedFieldChange, 
                               fieldOptions, 
                               onRequiredFieldChange,
                               required,
                               onRemove 
                              }) => {


  return (
    <div>
       <h2>TEXTFIELD PROPERTIES</h2>
      <TextField
        label="Label"
        value={label}
        onChange={(e) => onLabelChange(e.target.value)}
        fullWidth
        style={{marginTop: '10px'}}
      />
      <TextField
        label="Color"
        value={color}
        onChange={(e) => onColorChange(e.target.value)}
        fullWidth
        style={{marginTop: '10px'}}
      />
      <TextField
        label="Width"
        value={width}
        onChange={(e) => onWidthChange(e.target.value)}
        fullWidth
        style={{marginTop: '10px'}}
      />
      <TextField
        label="Height"
        value={height}
        onChange={(e) => onHeightChange(e.target.value)}
        fullWidth
        style={{marginTop: '10px'}}
      />
      <FormControl fullWidth style={{marginTop: '10px'}}>
        <InputLabel id="selected-field-label">Mapped Db Column</InputLabel>
        <Select
          labelId="selected-field-label"
          id="selected-field"
          value={selectedField}
          label="Selected Field"
          onChange={(e) => onSelectedFieldChange(e.target.value)}
          fullWidth
          style={{ marginTop: '10px' }}
        >
          {fieldOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControlLabel
        control={
          <Checkbox
            checked={required}
            onChange={(e) => onRequiredFieldChange(e.target.checked)}
            color="primary"
          />
        }
        label="Required"
        style={{ marginTop: '10px' }}
      />
       <Button
          variant="contained"
          color="error" 
          onClick={onRemove}
          fullWidth
          style={{marginTop: '10px'}}
        >
          Remove
      </Button>
    </div>
  );
};

TextFieldProperties.propTypes = {
  label: PropTypes.string.isRequired,
  selectedField: PropTypes.string.isRequired,
  onLabelChange: PropTypes.func.isRequired,
  onColorChange: PropTypes.func.isRequired,
  onWidthChange: PropTypes.func.isRequired,
  onHeightChange: PropTypes.func.isRequired,
  onSelectedFieldChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default TextFieldProperties;

import { ReactNode, useEffect } from "react";
import { Translate, useDraggable } from "@dnd-kit/core";

function DraggableElement({
  id,
  dragId,
  translate,
  children,
  setDragging
}) {
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id,
    data: {
      id: dragId,
      type: "draggable"
    }
  });

  useEffect(() => {
    setDragging(isDragging);
  }, [isDragging, setDragging]);
  
  return (
    <div
      ref={setNodeRef}
      style={
        {
          "--translate-x": `${translate?.x ?? 0}px`,
          "--translate-y": `${translate?.y ?? 0}px`
        }
      }
      {...listeners}
      {...attributes}
    >
      {children}
    </div>
  );
}

export default DraggableElement;

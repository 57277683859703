import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import HeadingProperties from '../FormElements/Heading/HeadingProperties';
import TextFieldProperties from '../FormElements/TextField/TextFieldProperties';
import ParagraphProperties from '../FormElements/Paragraph/ParagraphProperties';
import SignatureProperties from '../FormElements/Signature/SignatureProperties';
import RadioSelectionProperties from '../FormElements/RadioSelection/RadioSelectionProperties';
import FileUploadProperties from '../FormElements/FileUpload/FileUploadProperties';
import DatePickerComponentProperties from '../FormElements/DatePicker/DatePickerComponentProperties';
import CheckboxComponentProperties from '../FormElements/Checkbox/CheckboxComponentProperties';
import HorizontalContainerProperties from '../FormElements/HorizontalContainer/HorizontalContainerProperties'; 
import LogoComponentProperties from '../FormElements/Logo/LogoComponentPropertirs';


const fieldOptions = [
  "peoplesPeopleFieldsFirstName",
  "peoplesPeopleFieldsLastName",
  "peoplesPeopleFieldsEmail",
  "peoplesPeopleFieldsPersonalNo",
  "peoplesPeopleFieldsMobile",
  "peoplesPeopleFieldsAddress",
  "peoplesPeopleFieldsCity",
  "peoplesPeopleFieldsZipcode",
  "peoplesDetailsRecipientRole"
];


const PropertyEditor = ({ elementId, elementType, elementProps, onPropsChange, elements }) => {
  const [selectedFieldOptions, setSelectedFieldOptions] = useState({});
  // const [selectedField, setSelectedField] = useState(null);

    // useEffect(() => {
    //   const initialSelectedFields = elements.reduce((acc, item) => {
    //     const { props: { selectedField } = {} } = item;
    //     return { ...acc, [item.id]: selectedField }; 
    //   }, {});
    //   delete initialSelectedFields[elementId];
    //   setSelectedFieldOptions(initialSelectedFields);
    // }, [elements]);

    useEffect(() => {
      const collectSelectedFields = (items) => {
        return items.reduce((acc, item) => {
          const { props: { selectedField } = {} } = item;
          if (selectedField) {
            acc[item.id] = selectedField;
          }
          if (item.items && item.items.length > 0) {
            const nestedFields = collectSelectedFields(item.items);
            return { ...acc, ...nestedFields };
          }
          return acc;
        }, {});
      };
    
      const initialSelectedFields = collectSelectedFields(elements);
      delete initialSelectedFields[elementId];
      setSelectedFieldOptions(initialSelectedFields);
    }, [elements, elementId]);
    



  const renderPropertiesEditor = () => {
    switch (elementType) {
      case 'heading':
        return (
          <HeadingProperties
            {...elementProps}
            elementId={elementId}
            onTextChange={(text) => onPropsChange({ ...elementProps, text })}
            onWidthChange={(width) => onPropsChange({ ...elementProps, width })}
            onHeightChange={(height) => onPropsChange({ ...elementProps, height })}
            onMarginChange={(margin) => onPropsChange({ ...elementProps, margin })}
            onMarginLeftChange={(marginLeft) => onPropsChange({ ...elementProps, marginLeft })}
            onBackgroundColorChange={(backgroundColor) => onPropsChange({ ...elementProps, backgroundColor })}
            onRemove={() => onPropsChange({ ...elementProps, remove: true })}
          />
        );
      case 'textField':
        return (
          <TextFieldProperties
            {...elementProps}
            elementId={elementId}
            onLabelChange={(label) => onPropsChange({ ...elementProps, label })}
            onColorChange={(color) => onPropsChange({ ...elementProps, color })}
            onWidthChange={(width) => onPropsChange({ ...elementProps, width })}
            onHeightChange={(height) => onPropsChange({ ...elementProps, height })}
            onRequiredFieldChange={(required) => onPropsChange({...elementProps, required})}
            onSelectedFieldChange={(selectedField) => onPropsChange({ ...elementProps, selectedField })}
            fieldOptions={fieldOptions.filter((option) => !Object.values(selectedFieldOptions).includes(option))}
            onRemove={() => onPropsChange({ ...elementProps, remove: true })}
          />
        );
      case 'paragraph':
        return (
          <ParagraphProperties
            {...elementProps}
            elementId={elementId}
            onTextChange={(text) => onPropsChange({ ...elementProps, text })}
            onColorChange={(color) => onPropsChange({ ...elementProps, color })}
            onWidthChange={(width) => onPropsChange({ ...elementProps, width })}
            onHeightChange={(height) => onPropsChange({ ...elementProps, height })}
            onBackgroundColorChange={(backgroundColor) => onPropsChange({ ...elementProps, backgroundColor })}
            onPaddingChange={(padding) => onPropsChange({ ...elementProps, padding })}
            onFontSizeChange={(fontSize) => onPropsChange({ ...elementProps, fontSize })}
            onRemove={() => onPropsChange({ ...elementProps, remove: true })}
          />
        );
      case 'signature':
        return (
          <SignatureProperties
            {...elementProps}
            elementId={elementId}
            onLabelChange={(label) => onPropsChange({ ...elementProps, label })}
            onWidthChange={(width) => onPropsChange({ ...elementProps, width })}
            onHeightChange={(height) => onPropsChange({ ...elementProps, height })}
            onRequiredFieldChange={(required) => onPropsChange({...elementProps, required})}
            onRemove={() => onPropsChange({ ...elementProps, remove: true })}
          />
        );
      case 'radioSelection':
        return (
          <RadioSelectionProperties
            {...elementProps}
            elementId={elementId}
            onLabelChange={(label) => onPropsChange({ ...elementProps, label })}
            onOptionsChange={(options) => onPropsChange({ ...elementProps, options })}
            onWidthChange={(width) => onPropsChange({ ...elementProps, width })}
            onHeightChange={(height) => onPropsChange({ ...elementProps, height })}
            onSelectedFieldChange={(selectedField) => onPropsChange({ ...elementProps, selectedField })}
            onRequiredFieldChange={(required) => onPropsChange({...elementProps, required})}
            fieldOptions={fieldOptions.filter((option) => !Object.values(selectedFieldOptions).includes(option))}
            onRemove={() => onPropsChange({ ...elementProps, remove: true })}
          />
        );
      case 'fileUpload':
        return (
          <FileUploadProperties
            {...elementProps}
            onLabelChange={(label) => onPropsChange({ ...elementProps, label })}
            onWidthChange={(width) => onPropsChange({ ...elementProps, width })}
            onHeightChange={(height) => onPropsChange({ ...elementProps, height })}
            onRequiredFieldChange={(required) => onPropsChange({...elementProps, required})}
            onRemove={() => onPropsChange({ ...elementProps, remove: true })}
            elementId={elementId}
          />
        );
      case 'checkBox':
        return (
          <CheckboxComponentProperties
            {...elementProps}
            onLabelChange={(label) => onPropsChange({ ...elementProps, label })}
            onWidthChange={(width) => onPropsChange({ ...elementProps, width })}
            onHeightChange={(height) => onPropsChange({ ...elementProps, height })}
            elementId={elementId}
            onSelectedFieldChange={(selectedField) => onPropsChange({ ...elementProps, selectedField })}
            onRequiredFieldChange={(required) => onPropsChange({...elementProps, required})}
            fieldOptions={fieldOptions.filter((option) => !Object.values(selectedFieldOptions).includes(option))}
            onRemove={() => onPropsChange({ ...elementProps, remove: true })}
          />
        );
      case 'datePicker':
        return (
          <DatePickerComponentProperties
            {...elementProps}
            onLabelChange={(label) => onPropsChange({ ...elementProps, label })}
            onWidthChange={(width) => onPropsChange({ ...elementProps, width })}
            onHeightChange={(height) => onPropsChange({ ...elementProps, height })}
            elementId={elementId}
            onSelectedFieldChange={(selectedField) => onPropsChange({ ...elementProps, selectedField })}
            onRequiredFieldChange={(required) => onPropsChange({...elementProps, required})}
            fieldOptions={fieldOptions.filter((option) => !Object.values(selectedFieldOptions).includes(option))}
            onRemove={() => onPropsChange({ ...elementProps, remove: true })}
          />
        );
      case 'horizontalContainer':
        return (
          <HorizontalContainerProperties
            {...elementProps}
            onRow1WidthChange={(width) => onPropsChange({ ...elementProps, width })}
            onRow2WidthChange={(width) => onPropsChange({ ...elementProps, width })}
            onRowHeightChange={(height) => onPropsChange({ ...elementProps, height })}
            onRowWidthChange={(width) => onPropsChange({ ...elementProps, width })}
            onRemove={() => onPropsChange({ ...elementProps, remove: true })}
            elementId={elementId}
          />
        );
        case 'logo':
          return (
            <LogoComponentProperties
              {...elementProps}
              onRemove={() => onPropsChange({ ...elementProps, remove: true })}
              onWidthChange={(width) => onPropsChange({ ...elementProps, width })}
              onHeightChange={(height) => onPropsChange({ ...elementProps, height })}
              elementId={elementId}
            />
          );
      default:
        return <div>No properties to edit</div>;
    }
  };

  return (
    <div>
      {renderPropertiesEditor()}
    </div>
  );
};

PropertyEditor.propTypes = {
  elementType: PropTypes.string.isRequired,
  elementProps: PropTypes.object,
  onPropsChange: PropTypes.func.isRequired,
};

export default PropertyEditor;

// ParagraphProperties.jsx
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {TextField,Box} from '@mui/material';
import { useEffect } from "react";
import Button from '@mui/material/Button';
import Editor from "../../Editor/Editor";
import FullscreenToggle from '../../FullScreenToggleBtn';


const ParagraphProperties = ({
  text,
  color,
  backgroundColor,
  fontSize,
  padding,
  height,
  width,
  onTextChange,
  onColorChange,
  onBackgroundColorChange,
  onFontSizeChange,
  onPaddingChange,
  onHeightChange,
  onWidthChange,
  elementId,
  onRemove
}) => {
  useEffect(() => {
    // Re-render the Editor when elementId changes
    console.log("Editor re-rendered due to elementId change:", elementId);
    console.log(text);
  }, [elementId, text]);


  return (
    <>
        <h2>PARAGRAPH PROPERTIES</h2>
      <Editor
        label="Color"
        onTextChange={(value) => onTextChange(value)}
        fullWidth
        elementId={elementId}
        text={text}
      />
      <TextField
        label="Color"
        value={color}
        onChange={(e) => onColorChange(e.target.value)}
        fullWidth
        style={{marginTop: '10px'}}

      />
      <TextField
        label="Background Color"
        value={backgroundColor}
        onChange={(e) => onBackgroundColorChange(e.target.value)}
        fullWidth
        style={{marginTop: '10px'}}
      />
      <TextField
        label="Font Size"
        value={fontSize}
        onChange={(e) => onFontSizeChange(e.target.value)}
        fullWidth
        style={{marginTop: '10px'}}
      />
      <TextField
        label="Padding"
        value={padding}
        onChange={(e) => onPaddingChange(e.target.value)}
        fullWidth
        style={{marginTop: '10px'}}
      />
      <TextField
        label="Height"
        value={height}
        onChange={(e) => onHeightChange(e.target.value)}
        fullWidth
        style={{marginTop: '10px'}}
      />
      <TextField
        label="Width"
        value={width}
        onChange={(e) => onWidthChange(e.target.value)}
        fullWidth
        style={{marginTop: '10px'}}
      />
       <Button
          variant="contained"
          color="error" 
          onClick={onRemove}
          fullWidth
          style={{marginTop: '10px'}}
        >
          Remove
      </Button>
    </>
  );
};

ParagraphProperties.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  fontSize: PropTypes.string.isRequired,
  padding: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  onTextChange: PropTypes.func.isRequired,
  onColorChange: PropTypes.func.isRequired,
  onBackgroundColorChange: PropTypes.func.isRequired,
  onFontSizeChange: PropTypes.func.isRequired,
  onPaddingChange: PropTypes.func.isRequired,
  onHeightChange: PropTypes.func.isRequired,
  onWidthChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default ParagraphProperties;

import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useDispatch } from 'react-redux';
import { updateElement } from '../../../store/formSlice';

const CheckboxComponent = ({ elementId, label, checked, required, error, width, height }) => {
  const dispatch = useDispatch();
  const handleChange = (event) => {
    const updatedElement = {
      id: elementId,
      element: {
        elementId: elementId,
        value:event.target.checked,
      },
    };



    dispatch(updateElement(updatedElement));
  };


  return (
    <div style={{ marginLeft: '5px', marginTop: '5px' }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={handleChange}
            style={{
              width: {
                xs: '100%',
                sm: '75%',
                md: '50%',
                lg: width,
                xl: width,
              },
              height: {
                xs: 'auto',
                sm: 'auto',
                md: 'auto',
                lg: height,
                xl: height,
              },
              color: error ? 'red' : 'inherit',
            }}
          />
        }
        label={
          <span>
           {required && <span>*</span>} {label} 
          </span>
        }
      />
    </div>
  );
};

CheckboxComponent.propTypes = {
  elementId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  required: PropTypes.bool, // Add required prop type
  width: PropTypes.string,
  height: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired, // Add isSubmitting prop type
};

CheckboxComponent.defaultProps = {
  width: 'auto',
  height: 'auto',
};

export default CheckboxComponent;

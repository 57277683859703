import { useParams, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import FormElement from "../components/FormBuilder/FormElement";
import { Grid, Button, Paper, Box, IconButton,Tooltip } from "@mui/material";
import { FormProvider } from "../FormContext";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import LoadingOverlay from '../components/loadingOverlay/LoadingOverlay'
import DraggableElement from './DraggableElement';
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { formElements } from "../store/formSlice";
import {
  generatePdf,
  uploadPdfToS3,
  fetchFormData,
  fetchSubContractorDetail,
  mergeElementsWithFormValues
} from '../utils/formUtils';
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const FormFillPage = (props) => {
  const navigate = useNavigate();
  const { clientId, documentType, subcontractorId } = useParams();
  const [loading, setLoading] = useState(false);

  const formElementValues = useSelector(formElements);
  const [elements, setElements] = useState([]);
  const ToCaptureRef = useRef();
  const signatureRefs = useRef({}); // Initialize signatureRefs as an empty object

  const validateRequiredFields = () => {
    let isValid = true;

    const updatedElements = elements.map((element) => {
      if (element.props.required && !element.props.value) {
        isValid = false;
        return {
          ...element,
          props: {
            ...element.props,
            error: true,
            helperText: 'This field is required',
          },
        };
      }
      return {
        ...element,
        props: {
          ...element.props,
          error: false,
          helperText: '',
        },
      };
    });
    setElements(updatedElements);
    return isValid;
  };

  const handleSign = async () => {
    mergeElementsWithFormValues(elements, formElementValues);
    const isValid = validateRequiredFields();
    if (!isValid) {
      const firstInvalidElement = elements.find(
        (element) => element.props.required && !element.props.value
      );
      const elementNode = document.getElementById(firstInvalidElement.id);
      if (elementNode) {
        elementNode.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return;
    }
    setLoading(true);
        // const { pdfBlob, fileUniqueName } = await generatePdf(ToCaptureRef);
    // await uploadPdfToS3(pdfBlob, fileUniqueName);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/SignHHManagerDocument`,
        {
          headers: { "Content-Type": "application/json" },
          method: "POST",
          body: JSON.stringify({
            hhManagerModelRequest: {
              subContractorId: subcontractorId,
              clientId: clientId,
              documentType: documentType,
              responseJson: elements,
              responseHtml: "",
            },
            accessKey: process.env.REACT_APP_HHMANAGER_API_KEY,
          }),
        }
      );
      setLoading(false);
      if (res.ok) {
        console.log("Form saved successfully!");
        toast.success("Contract signed successfully!!");
        navigate('/thank-you');
      } else {
        console.error("Failed to save form:", await res.text());
        toast.error("Failed to sign contract:", await res.text());
      }
    } catch (error) {
      setLoading(false);
      console.error("Error while saving form:", error);
      toast.error("Error while signing contract:", error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const [formData, subcontractorDetail] = await Promise.all([
        fetchFormData(clientId),
        fetchSubContractorDetail(clientId, subcontractorId),
      ]);

      if (!formData || !subcontractorDetail) return;

      const processElement = (element) => {
        if (element.type !== 'textField') return element;

        const fieldMappings = {
          peoplesPeopleFieldsFirstName: 'FirstName',
          peoplesPeopleFieldsLastName: 'LastName',
          peoplesPeopleFieldsEmail: 'Email',
          peoplesPeopleFieldsPersonalNo: 'MobileNumber',
          peoplesPeopleFieldsMobile: 'MobileNumber',
          peoplesPeopleFieldsAddress: 'AddressLine1',
          peoplesPeopleFieldsCity: 'City',
          peoplesDetailsRecipientRole: 'Subcontractor',
          peoplesPeopleFieldsZipcode: 'Postcode',
        };

        const detailKey = fieldMappings[element.props.selectedField];
        if (detailKey) {
          return {
            ...element,
            props: {
              ...element.props,
              value: subcontractorDetail[detailKey] || element.props.value,
            },
          };
        }
        return element;
      };

      const processList = (list) =>
        list.map((item) => {
          if (item.items) {
            return { ...item, items: processList(item.items) };
          }
          return processElement(item);
        });

      const mergedElements = processList(formData);
      setElements(mergedElements);
    }

    fetchData();
  }, [clientId, subcontractorId]);

  const scrollToSection = () => {
    // if (ref && ref.current) {
    //   ref.current.scrollIntoView({ behavior: "smooth" });
    // }
    const element = document.getElementById("btnSignature");
    if (element) {
      element.scrollIntoView({  behavior: 'smooth', block: 'center'});
    }
  };

  return (
    <FormProvider>
      <Box position="relative" minHeight="100vh">
        <Grid container justifyContent="center" ref={ToCaptureRef} sx={{
        backgroundColor: '#CCCCCC',
      }}>
          <Grid item xs={12} md={8}>
            <DndContext autoScroll={false} collisionDetection={closestCenter}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Paper
                      elevation={3}
                      sx={{ padding: 2, minHeight: "700px" }}
                    >
                      <Grid container spacing={2}>
                      <Grid item xs={12}  position="fixed" sx={{ display: 'flex', right: 300,justifyContent: 'flex-end',zIndex: (theme) => theme.zIndex.drawer + 1}}>
                      <Tooltip title="CLICK TO SIGN" arrow>
                        <IconButton
                          color="primary"
                          onClick={() => {
                            // const signatureRef = Object.values(
                            //   signatureRefs.current
                            // )[0]; // Get the first signature ref
                            scrollToSection();
                          }}
                          size="large"
                        >
                          <ArrowDownwardIcon fontSize="large" />
                        </IconButton>
                        </Tooltip>
                        </Grid>
                        <SortableContext
                          items={elements}
                          strategy={verticalListSortingStrategy}
                        >
                          {elements.map((element, index) => {
                            // if (element.type === "signature") {
                            //   signatureRefs.current[element.id] =
                            //     React.createRef();
                            // }
                            return (
                              <Grid
                                xs={12}
                                item
                                key={element.id}
                                sx={{
                                  ...(element.type === "signature" ||
                                  element.type === "fileUpload"
                                    ? { pageBreakInside: "avoid" }
                                    : {}),
                                  ...(element.type === "logo" && {
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }),
                                }}
                                // ref={
                                //   element.type === "signature"
                                //     ? signatureRefs.current[element.id]
                                //     : null
                                // }
                              >
                                <DraggableElement id={element.id} index={index}>
                                  <FormElement
                                    element={element}
                                    dragHandle={false}
                                  />
                                </DraggableElement>
                              </Grid>
                            );
                          })}
                        </SortableContext>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Box>
            </DndContext>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: "20px",
              marginBottom: "20px",
              textAlign: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" },
                padding: { xs: "12px 18px", sm: "14px 20px", md: "16px 24px" },
              }}
              onClick={handleSign}
            >
              Sign Contract
            </Button>
          </Grid>
        </Grid>
        <LoadingOverlay open={loading} />
      </Box>
    </FormProvider>
  );
};

export default FormFillPage;

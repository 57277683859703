import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { useDispatch, useSelector } from 'react-redux';
import { updateElement } from '../../../store/formSlice';

const RadioSelection = (props) => {
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState(0);

  const handleChange = (event) => {
    const updatedElement = {
      id: props.elementId,
      element: {
        ...props,
        value: event.target.value,
      },
    };
    setSelectedValue(event.target.value);
    dispatch(updateElement(updatedElement));
  };

  return (
    <div sx={{ width: { xs: '100%', sm: props.width || '75%', md: props.width || '60%' },
    height: { xs: 'auto', sm: props.height || 'auto', md: props.height || 'auto%' }, }}>
      <Box boxShadow={2} p={2}>
        {props.label && <Typography variant="subtitle1" gutterBottom>{props.label}</Typography>}
        <FormControl component="fieldset">
          <RadioGroup aria-label={props.label} name={props.label} value={selectedValue} onChange={handleChange}>
            {props.options.map((option, index) => (
              <Typography key={index} variant="body1" style={{ marginBottom: '8px' }}>
                <Radio
                  checked={selectedValue === option} // Check if the option matches current value
                  value={option}
                  color="primary"
                />
                {option}
              </Typography>
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    </div>
  );
};

RadioSelection.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default RadioSelection;

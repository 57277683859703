// InlineFields.js
import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import React from 'react';

const TextFieldNodeView = ({ node, updateAttributes }) => (
  <input
    type="text"
    {...node.attrs}
    contentEditable={false}
  />
);

const DatePickerNodeView = ({ node, updateAttributes }) => (
  <input
    type="date"
    {...node.attrs}
    contentEditable={false}
  />
);

export const InlineFields = Node.create({
  name: 'inlineFields',

  group: 'inline',

  inline: true,

  atom: true,

  addAttributes() {
    return {
      type: {
        default: 'text', // or 'date'
      },
      placeholder: {
        default: '',
      },
      defaultValue: {
        default: '',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'input',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['input', mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ({ node }) => {
      if (node.attrs.type === 'text') {
        return ReactNodeViewRenderer(TextFieldNodeView);
      } else if (node.attrs.type === 'date') {
        return ReactNodeViewRenderer(DatePickerNodeView);
      }
    };
  },

  addCommands() {
    return {
      setTextField: attributes => ({ commands }) => {
        return commands.insertContent({
          type: this.name,
          attrs: { ...attributes, type: 'text' },
        });
      },
      setDatePicker: attributes => ({ commands }) => {
        return commands.insertContent({
          type: this.name,
          attrs: { ...attributes, type: 'date' },
        });
      },
    };
  },
});

import React, { createContext, useState } from 'react';

const FormContext = createContext({
  formElements: [],
  updateElement: () => {},
});

const FormProvider = ({ children }) => {
  const [formElements, setFormElements] = useState([]);

  const updateElement = (id, updatedElement) => {
    setFormElements((prevElements) => {
      const updatedElements = [...prevElements];
      const index = updatedElements.findIndex((el) => el.id === id);
      updatedElements[index] = updatedElement;
      return updatedElements;
    });
  };

  const contextValue = { formElements, updateElement };

  return (
    <FormContext.Provider value={contextValue}>{children}</FormContext.Provider>
  );
};

export { FormContext, FormProvider };

// MenuButtonInlineFields.js
import React from 'react';
import { IconButton, Tooltip, Popover, MenuItem, Menu } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

export const MenuButtonInlineFields = ({ editor }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  if (!editor) {
    return null;
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddTextField = () => {
    editor.chain().focus().setTextField({ placeholder: 'Enter text here' }).run();
    handleClose();
  };

  const handleAddDatePicker = () => {
    editor.chain().focus().setDatePicker({ placeholder: 'Select date' }).run();
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'inline-fields-popover' : undefined;

  return (
    <>
      <Tooltip title="Add Inline Field">
        <IconButton size="small" onClick={handleClick}>
          <AddIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleAddTextField}>Add Text Field</MenuItem>
          <MenuItem onClick={handleAddDatePicker}>Add Date Picker</MenuItem>
        </Menu>
      </Popover>
    </>
  );
};

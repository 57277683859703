import { useParams, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Grid, Button, Paper, Box,Typography,FormControl, FormLabel, RadioGroup, Radio, FormControlLabel,Checkbox } from "@mui/material";



const RadioSelectionFillElement=({element })=>{

    const [selectedValue, setSelectedValue] = useState(element.props.value);
     const handleChange = (event) => {
        console.log('Selected Value:', event.target.value);
        setSelectedValue(event.target.value);
     }
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">{element.props.label}</FormLabel>
              <RadioGroup
                aria-label={element.props.label}
                name={element.id}
                value={selectedValue}
                onChange={handleChange}
              >
                {element.props.options.map((option, index) => (
                  <FormControlLabel key={index} value={option} control={<Radio />} label={option} />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      );
    }
    
export default RadioSelectionFillElement;
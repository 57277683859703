import { useParams, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import FormElement from "../../components/FormBuilder/FormElement";
import { Grid, Button, Paper, Box,Typography,FormControl, FormLabel, RadioGroup, Radio, FormControlLabel,Checkbox } from "@mui/material";
import SignatureFileFillElement from './SignatureFileFillElement';
import RadioSelectionFillElement from './RadioSelectionFillElement';
import CheckboxFillElement from "./CheckboxFillElement";



const HorizontalContainerFillElement=({element })=>{

   
    return (
       <Box display="flex" width="100%" alignItems="center" style={{ minHeight: '50px' }}>
       <Grid container spacing={2}>
         {element.items.map((item) => (
           <Grid item key={item.id} xs={12} sm={6} md={4}>
             {item.type === 'signature' || item.type === 'fileUpload' ? (
               <SignatureFileFillElement element={item} />
             ) : item.type === 'radioSelection' ? (
               <RadioSelectionFillElement element={item} />
             ) : item.type === 'checkBox' ? (
               <CheckboxFillElement element={item} />
             ) : (
               <FormElement element={item} dragHandle={false} />
             )}
           </Grid>
         ))}
       </Grid>
     </Box>
     );
  }
    
export default HorizontalContainerFillElement;
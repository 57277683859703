import React, { useState, useEffect, useContext } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import BackgroundColorContext from '../../context/BackgroundColorContext';



const StyledMenuItem = styled(MenuItem)(({ theme, level }) => {
    const baseStyle = {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.text.primary,
    };
  
    switch (level) {
      case 1:
        return {
          ...baseStyle,
          fontSize: '2em',
          fontWeight: 'bold',
        };
      case 2:
        return {
          ...baseStyle,
          fontSize: '1.5em',
          fontWeight: 'bold',
        };
      case 3:
        return {
          ...baseStyle,
          fontSize: '1.17em',
          fontWeight: 'bold',
        };
      case 4:
        return {
          ...baseStyle,
          fontSize: '1em',
          fontWeight: 'bold',
        };
      case 5:
        return {
          ...baseStyle,
          fontSize: '0.83em',
          fontWeight: 'bold',
        };
      case 6:
        return {
          ...baseStyle,
          fontSize: '0.67em',
          fontWeight: 'bold',
        };
      case 'hhHeading':
        return {
          ...baseStyle,
        };
      default:
        return baseStyle;
    }
  });

const CustomMenuSelectHeading = ({ editor }) => {
  const [currentHeading, setCurrentHeading] = useState('paragraph');
  const { backgroundColor } = useContext(BackgroundColorContext);


  const headingOptions = [
    { level: 'paragraph', label: 'Paragraph' },
    { level: 1, label: 'Heading 1' },
    { level: 2, label: 'Heading 2' },
    { level: 3, label: 'Heading 3' },
    { level: 4, label: 'Heading 4' },
    { level: 5, label: 'Heading 5' },
    { level: 6, label: 'Heading 6' },
    { level: 'hhHeading', label: 'HH Heading' },
  ];

  useEffect(() => {
    if (editor && editor.isActive) {
      const updateHeading = () => {
        const newHeading = headingOptions.find(
          (option) =>
            (editor.isActive('paragraph') && option.level === 'paragraph') ||
            editor.isActive('heading', { level: option.level }) ||
            (editor.isActive('hhHeading') && option.level === 'hhHeading')
        )?.level || 'paragraph';
        setCurrentHeading(newHeading);
      };

      editor.on('selectionUpdate', updateHeading);
      editor.on('update', updateHeading);

      // Initial update
      updateHeading();

      return () => {
        editor.off('selectionUpdate', updateHeading);
        editor.off('update', updateHeading);
      };
    }
  }, [editor]);

  const handleChange = (event) => {
    if (!editor) return;
    
    const value = event.target.value;
    if (value === 'hhHeading') {
      editor.chain().focus().toggleNode('hhHeading', 'paragraph').run();
    } else if (value === 'paragraph') {
      editor.chain().focus().setParagraph().run();
    } else {
      editor.chain().focus().toggleHeading({ level: parseInt(value) }).run();
    }
  };

  if (!editor) {
    return null; // or return a loading state if you prefer
  }

  return (
    <Select
      value={currentHeading}
      onChange={handleChange}
      size="small"
    >
      {headingOptions.map((option) => (
        <StyledMenuItem key={option.level} value={option.level} level={option.level}>
          {option.level === 'hhHeading' ? (
            <span className="hh-heading">{option.label}</span>
          ) : (
            option.label
          )}
        </StyledMenuItem>
      ))}
    </Select>
  );
};

export default CustomMenuSelectHeading;
// src/components/AuthHandler.js
import React, { useEffect, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

const AuthHandler = () => {
  const { setAuthData } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleClose = () => setOpen(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tempCode = params.get('code');
    const redirectUrl = params.get('redirect');

    if (tempCode) {
      axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/v1/get-access-token?tempCode=${tempCode}`)
        .then(response => {
          const { Token, Role, UserId, TokenExpiry, Name, Email } = response.data;
          if (Token == null) {
            setErrorMessage('Failed to get access token. Please try again.');
            setOpen(true);
          } else {
            setAuthData({Token, Role, UserId, TokenExpiry, Name, Email });
            if (redirectUrl) {
              window.location.href = redirectUrl; // Redirect to the specified URL
            } else {
              navigate(location.pathname); // Fallback to navigating to the current path
            }
          }
        })
        .catch(error => {
          console.error('Failed to get access token', error);
          setErrorMessage('Failed to get access token. Please try again.');
          setOpen(true);
        });
    }
  }, [location, setAuthData, navigate]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AuthHandler;


import PropTypes from 'prop-types';
import Heading from './FormElements/Heading/Heading';
import TextField from './FormElements/TextField/TextField';

import { useParams, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import FormElement from "../components/FormBuilder/FormElement";
import { Grid, Button, Paper, Box } from "@mui/material";
import { FormProvider } from "../FormContext";
import { useSelector } from "react-redux";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

import { formElements } from "../store/formSlice";

import {
  DndContext,
  closestCenter,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import toast from "react-hot-toast";
import LoadingOverlay from '../components/loadingOverlay/LoadingOverlay'
const FormPreview = ({ elements }) => {
  // const renderElement = (element) => {
  //   switch (element.type) {
  //     case 'heading':
  //       return <Heading {...element.props} />;
  //     case 'textField':
  //       return <div><strong>{element.props.label}:</strong> [Text field]</div>;
  //     default:
  //       return <div>Unsupported element type</div>;
  //   }
  // };

  return (
    <FormProvider>
    <Grid container justifyContent="center" >
      <Grid item xs={12} md={8}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper elevation={3} sx={{ padding: 2, minHeight: "700px" }}>
                  <Grid container spacing={2}>
                      {elements.map((element, index) => (
                        <Grid xs={12} item key={element.id}  sx={{
                          ...(element.type === 'logo' && {
                            display: 'flex',
                            justifyContent: 'center', 
                            alignItems: 'center',
                          }),
                        }}>
                            <FormElement element={element} dragHandle={false} />
                        </Grid>
                      ))}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Box>
      </Grid>
    </Grid>
  </FormProvider>
  );
};

FormPreview.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default FormPreview;

import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useDispatch } from 'react-redux';
import { updateElement } from '../../../store/formSlice';
import { Box } from '@mui/material';

const LogoComponent = ({ elementId, base64Src, alt, width, height }) => {
  // const dispatch = useDispatch();
  // const handleChange = (event) => {
  //   const updatedElement = {
  //     id: elementId,
  //     element: {
  //       elementId: elementId,
  //       value:event.target.checked,
  //     },
  //   };



  //   dispatch(updateElement(updatedElement));
  // };


  return (
    <Box
    justifyContent="center"
      component="img"
      src={`data:image/png;base64,${base64Src}`}
      alt={alt}
      sx={{
        width: {
          xs: '100%',   // Full width on extra-small screens
          sm: '75%',    // 75% width on small screens
          md: '50%',    // 50% width on medium screens
          lg: width,  // Use provided width on large screens
          xl: width   // Use provided width on extra-large screens
        },
        height: {
          xs: 'auto',   // Auto height on extra-small screens
          sm: 'auto',   // Auto height on small screens
          md: 'auto',   // Auto height on medium screens
          lg: height,  // Use provided height on large screens
          xl: height   // Use provided height on extra-large screens
        },
      }}
    />
  );
};

LogoComponent.propTypes = {
    base64Src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
  };
export default LogoComponent;

// src/App.js
import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import FormEditorPage from './pages/FormEditorPage';
import FormFillPage from './pages/FormFillPage';
import ThankYouPage from './pages/ThankYouPage';
import ContractVersion from './pages/ContractVersionPage';
import { Toaster } from 'react-hot-toast';
import { AuthProvider, AuthContext } from './context/AuthContext';
import { BackgroundColorProvider } from './context/BackgroundColorContext';
import AuthHandler from './components/AuthHandler';
import Header from './components/Header';
import LoginPage from './pages/LoginPage';
import FormDownloadPage from './pages/FormDownloadPage'
import FormFillDownloadPage from './pages/FormFillDownloadPage';


const PrivateRoute = ({ element }) => {
  const { auth } = useContext(AuthContext);
  const location = useLocation();

  if (!auth.Token) {
    const currentUrl = encodeURIComponent(window.location.href);
    const baseUrl = window.location.origin;
    const redirectUrl = `${baseUrl}/auth-callback?redirect=${currentUrl}`;
    window.location.href = `${process.env.REACT_APP_BASE_API_URL}/Auth/HHPortal?hhredirect=${encodeURIComponent(redirectUrl)}`;
    return null;
  }

  return (
    <>
      <Header />
      {element}
    </>
  );
};

function App() {
  return (
    <Provider store={store}>
      <AuthProvider>
      <BackgroundColorProvider>
        <Toaster position="top-right" />
        <Router>
          <Routes>
            <Route path='/auth-callback' element={<AuthHandler />} />
            <Route path='/login' element={<LoginPage />} />
            <Route path='/' element={<PrivateRoute element={<FormEditorPage />} />} exact />
            {/* <Route path='/:clientId' element={<PrivateRoute element={<FormEditorPage />} />} exact /> */}
            <Route path='/:clientId/form/:documentType/:subcontractorId' element={<FormFillPage />} />
            {/* <Route path='/:clientId/versions' element={<PrivateRoute element={<ContractVersion />} />} /> */}
            <Route path='/:clientId' element={<PrivateRoute element={<ContractVersion />} />} />
            <Route path='/:clientId/versions' element={<PrivateRoute element={<FormEditorPage />} />} exact />
            <Route path='/:clientId/versions/:action/:contractVersionId?' element={<PrivateRoute element={<FormEditorPage />} />} />
            <Route path="/thank-you" element={<ThankYouPage />} />
            <Route path='/:clientId/download/:contractVersionId' element={<FormDownloadPage />} />
            <Route path='/generate-pdf/:fileId' element={<FormFillDownloadPage />} />
            <Route path="*" element={<Navigate to="/not-found" />} />
          </Routes>
        </Router>
        </BackgroundColorProvider>
      </AuthProvider>
    </Provider>
  );
}

export default App;

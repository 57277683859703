import React from "react";
import { Box, Typography } from "@mui/material";

const ThankYouPage = () => {
  return (
    //   <Box
    //   sx={{
    //     display: 'flex',
    //     flexDirection: 'column',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     height: '100vh',
    //   }}
    // >
    //   <Typography variant="h3" component="h1" gutterBottom>
    //     Thank You!
    //   </Typography>
    //   <Typography variant="h5" component="p">
    //     Your contract has been signed successfully.
    //   </Typography>
    // </Box>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        padding: "20px",
        textAlign: "center",
        maxWidth: "600px",
      }}
    >
      <Typography variant="h3" component="h1" gutterBottom>
        Thank You!
      </Typography>
      <Typography variant="h5" component="p">
        Your contract has been signed successfully.
      </Typography>
    </Box>
  );
};

export default ThankYouPage;

// contractService.js

const BASE_URL = `${process.env.REACT_APP_BASE_API_URL}/api/v1`; // Adjust this base URL as per your API endpoint

/**
 * Function to update contract version details.
 * @param {string} id - ID of the contract version to update.
 * @param {Object} updatedData - Updated contract version data.
 * @returns {Promise} Promise that resolves to the updated contract version data.
 */
export const UpdateContractVersion = async (id, updatedData) => {
  console.log("UpdateContractVersion :", updatedData);
  try {
    const response = await fetch(
      `${BASE_URL}/HHContract/UpdateVersionContract`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedData),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    console.log("Update Successfully");
    //return await response.json();
  } catch (error) {
    console.error("Error updating contract version:", error);
    throw error;
  }
};

/**
 * Function to update the IsActive status of a contract version.
 * @param {string} id - ID of the contract version to update.
 * @returns {Promise} Promise that resolves when the update is successful.
 */
export const UpdateContractActive = async (id) => {
  try {
    const response = await fetch(
      `${BASE_URL}/HHContract/ActiveContract?id=${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        // body: JSON.stringify({ IsActive: isActive }),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    console.log(`Updated IsActive status to `);
    // Optionally return await response.json();
  } catch (error) {
    console.error("Error updating IsActive status:", error);
    throw error;
  }
};

/**
 * Function to update the IsActive status of a contract version.
 * @param {string} id - ID of the contract version to update.
 * @returns {Promise} Promise that resolves when the update is successful.
 */
export const CopyHHContract = async (id) => {
  try {
    const response = await fetch(
      `${BASE_URL}/HHContract/CopyContract?id=${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        // body: JSON.stringify({ IsActive: isActive }),
      }
    );
    console.log(response);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    console.log(`Updated IsActive status to `);
    // Optionally return await response.json();
  } catch (error) {
    console.error("Error updating IsActive status:", error);
    throw error;
  }
};

export const FetchData = async (clientId) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/api/v1/HHContract/GetHHContractsListByClientId?clientId=${clientId}`, 
      {
        headers: { "Content-Type": "application/json" },
        method: "GET",
      }
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const result = await response.json();
    return result;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const fetchFormFillData = async (id) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/api/v1/HHContract/GeneratePdf?id=${id}`,
      {
        headers: { "Content-Type": "application/json" },
        method: "GET",
      }
    );
    if (res.ok) {
      const json = await res.json();
      console.log("Form elements fetched successfully:", json.MetaData);
      return json.MetaData;
    } else {
      console.error("Failed to fetch form elements:", await res.text());
    }
  } catch (error) {
    console.error("Error while fetching form elements:", error);
  }
};
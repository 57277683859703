import React, { useState, useCallback, useEffect,useRef  } from "react";
import { createPortal } from "react-dom";
import { v4 as uuidv4 } from "uuid";
import FormElement from "./FormElement";
import PropertyEditor from "./PropertyEditor";
import { Grid, Button, Paper, Box } from "@mui/material";
import DraggableElement from './DraggableElement';
import SortableElement from "./SortableElement"; 
import defaultElementTypes from "./elementTypes";
import Clonedeep from "lodash.clonedeep";
import "../../App.css";
import {
  DndContext,
  rectIntersection,
  closestCenter,
  MouseSensor,
  TouchSensor,
  KeyboardSensor,
  useSensor,
  useSensors,
  DragOverlay
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  sortableKeyboardCoordinates
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  findElementIndex, 
  findElementById, 
  removeElement, 
  findRootElement
} from "./utils";
// import FullscreenToggle from "../FullScreenToggleBtn";

const defaultCoordinates = {
  x: 0,
  y: 0
};

console.log("elementType");
console.log(defaultElementTypes);

const FormBuilder = ({ elements, setElements }) => {
  const [selectedElementId, setSelectedElementId] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const [elementTypes, setElementTypes] = useState(defaultElementTypes);
  const [dragType, setDragType] = useState(null);
  const [dragId, setDragId] = useState(null);
  const [{ translate }, setTranslate] = useState({
    initialTranslate: defaultCoordinates,
    translate: defaultCoordinates
  });
  // const mainContentRef = useRef(null);
  const [initialWindowScroll, setInitialWindowScroll] = useState(defaultCoordinates);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );


  // const updateElementProps = useCallback(
  //   (id, newProps) => {
  //     setElements((prevElements) => {
  //       // Handle element removal if `remove` prop is true
  //       if (newProps.remove === true) {
  //         return prevElements.filter((element) => element.id !== id);
  //       }

  //       // Otherwise, update element properties
  //       return prevElements.map((element) =>
  //         element.id === id
  //           ? { ...element, props: { ...element.props, ...newProps } }
  //           : element
  //       );
  //     });
  //   },
  //   [setElements]
  // );

  const updateElementProps = useCallback(
    (id, newProps) => {
      const updateProps = (elements) => {
        return elements.map((element) => {
          if (element.id === id) {
            if (newProps.remove === true) {
              return null; // Mark element for removal
            }
            return { ...element, props: { ...element.props, ...newProps } };
          }
          if (element.items) {
            const updatedItems = updateProps(element.items).filter(item => item !== null);
            return { ...element, items: updatedItems };
          }
          return element;
        }).filter(element => element !== null); // Remove marked elements
      };
  
      setElements((prevElements) => updateProps(prevElements));
    },
    [setElements]
  );
  
  const handleDragStart = ({ active }) => {
    console.log(active);
    if (active.data.current.type !== "draggable") {
      console.log(active.id);
      setActiveId(active.id);
      setDragType("sortable");
    } else {
      setDragType("draggable");
      
    }

    setInitialWindowScroll({
      x: window.scrollX,
      y: window.scrollY
    });
  };
  
  const handleDragMove = ({ delta }) => {
    setTranslate(({ initialTranslate }) => ({
      initialTranslate,
      translate: {
        x: initialTranslate.x + delta.x - initialWindowScroll.x,
        y: initialTranslate.y + delta.y - initialWindowScroll.y
      }
    }));
  };

  const handleDragEnd = (event) => {
    if (dragType === 'draggable'){
      const id = uuidv4();
      const activeId = event.active?.id;

      // Handle the case when there is no element in the canvas and this is 
      // the first element.
      // In this case handlDragOver is not invoked.
      if (elements.length === 0){
        const element = elementTypes.find((element) => element.id === activeId);
        elements.push({
            id: activeId,
            ...element,
        })
      }

      const elementTypesCopy = Clonedeep(elementTypes);
      elementTypesCopy.find(element => element.id === activeId).id = id;
      setElementTypes(elementTypesCopy);
    }
    setDragId(null);
    setActiveId(null);
    setDragType(null);

    setTranslate(({ translate }) => {
      return {
        translate,
        initialTranslate: translate
      };
    });
    setInitialWindowScroll(defaultCoordinates);
  };

  const handleDragCancel = () => {
    setActiveId(null);
    setDragType(null);
  }
  
  const handleDragOver = ({ active, over }) => {
    if (!over || !active) {
      return;
    }

    const activeId = active.id;
    const overId = over.id;

    // If source == target for drag and drop or target drop is invalid,
    // don't proceed.
    if (activeId === overId || overId == null) return;

  
    const activeIndex = findElementIndex(elements, activeId);
  
    if (activeIndex === -1) {
      // If the element is new and being added to the container
      setElements((prevElements) => {
        const element = elementTypes.find((element) => element.id === activeId);
  
        const elementIndex     = findElementIndex(prevElements, overId);
        const targetContainer  = findRootElement(prevElements, overId)
        const newElement = {
          id: activeId,
          ...element,
        };

        const newElements = [...prevElements];
        if (newElement?.type === 'horizontalContainer' && targetContainer?.type === 'horizontalContainer'){
          // Do nothing.
        } else if (targetContainer?.type === 'horizontalContainer') {
          // If the target is a horizontal container, add the new element to its items
          if (targetContainer.items) {
            // Find the position within the elements here
            // And insert at the exact position.
            targetContainer.items.splice(elementIndex, 0, newElement);
          } else {
            targetContainer.items = [newElement];
          }
        } else {
          newElements.splice(elementIndex , 0, newElement);
        }
        return newElements;
      });
    } else {
      // If the element is being moved within or between containers
      setElements((prevElements) => {
        let newElements = [...prevElements];
        const targetContainer  = findRootElement(prevElements, overId);
        const activeElement    = findElementById(prevElements, activeId);

        if (activeElement.type === 'horizontalContainer' && targetContainer.type === 'horizontalContainer'){
          // Do nothing
        } else {
          // Remove the element from its current position
          const movedElement = removeElement(newElements, activeId);
    
          if (movedElement) {
            const overElementIndex = findElementIndex(newElements, overId);

            if (targetContainer?.type === 'horizontalContainer') {
              // If moving to a horizontal container, add it to the items of the target container
              if (targetContainer) {
                if (targetContainer.items) {
                  targetContainer.items.splice(overElementIndex, 0, movedElement);
                } else {
                  targetContainer.items = [movedElement];
                }
              }
            } else {
              // Move the element to the new position in the root level
              newElements.splice(overElementIndex, 0, movedElement);
            }
          }
        }
  
        return newElements;
      });
    }
  };
  
  
  
  const getDragLayer = () => {
    if (dragType === null) {
      return null;
    }

    // const layerId = dragType === "draggable" ? dragId : activeId;
    // const overLayStyles = {
    //   opacity: 1,
    //   backgroundColor: "gray",
    //   border: `1px solid gray`
    // };

    return (
      <>
        {dragType === "draggable" && (
          <Box
            style={{
              backgroundColor: '#1976d2',
              width: '200px',
              height: '30px',
              color: 'white',
              padding: '10px',
              borderRadius: '8px',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontFamily: 'Arial, sans-serif',
              fontSize: '16px',
              cursor: 'pointer'
            }}
          >
            DRAG AND DROP TO ADD
          </Box>
        )}
      </>
    );
  };

  return (
    <>
    {/* <FullscreenToggle targetRef={mainContentRef}/>
    <Box position="relative" ref={mainContentRef} > */}
    <DndContext
      sensors={sensors}
      collisionDetection={rectIntersection}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragMove={handleDragMove}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}  className="non-fullscreen">
          <Paper
            elevation={3}
            sx={{
              padding: 2,
              minHeight: "700px",
              position: { xs: "static", md: "sticky" },
              top: { xs: "auto", md: 16 },
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {elementTypes.map((element, index) => (
                <DraggableElement
                  id={element.id}
                  dragId={element.id}
                  setDragging={setDragging}
                  translate={translate}
                  key={element.id}
                >
                  <Button
                      variant="outlined"
                      key={index}
                      style={{width: '100%'}}
                    >
                      Add {element.type}
                    </Button>
                </DraggableElement>
              ))}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className="non-fullscreen">
          <Paper elevation={3} sx={{ padding: 2, minHeight: "700px" }}>
            <Grid container spacing={2}>
              <SortableContext
                items={elements}
                strategy={verticalListSortingStrategy}
              >
                {elements.map((element, index) => (
                  <Grid xs={12} item key={element.id}    sx={{
                    ...(element.type === 'logo' && {
                      display: 'flex',
                      justifyContent: 'center', 
                      alignItems: 'center',
                    }),
                  }}>
                    <SortableElement
                      setSelectedElementId={setSelectedElementId}
                      handle={true}
                      key={element.id}
                      id={element.id}
                      index={index} 
                      element={element}
                    >
                      <FormElement element={element} setSelectedElementId={setSelectedElementId} border={true} />
                    </SortableElement>
                  </Grid>
                ))}
              </SortableContext>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper
            elevation={3}
            sx={{
              padding: 2,
              minHeight: "700px",
              position: { xs: "static", md: "sticky" },
              top: { xs: "auto", md: 16 },
            }}
          >
            {selectedElementId && (
              <PropertyEditor
                elements={elements}
                elementId={selectedElementId}
                elementType={
                  findElementById(elements, selectedElementId)?.type
                }
                elementProps={
                  findElementById(elements, selectedElementId)?.props
                }
                onPropsChange={(newProps) =>
                  updateElementProps(selectedElementId, newProps)
                }
              />
            )}
          </Paper>
        </Grid>
      </Grid>
      {createPortal(<DragOverlay>{getDragLayer()}</DragOverlay>, document.body)}
    </DndContext>
    {/* </Box> */}
    </>
   
  );
};

export default FormBuilder;

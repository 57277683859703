import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Heading from '../FormElements/Heading/Heading';
import TextField from '../FormElements/TextField/TextField';
import Paragraph from '../FormElements/Paragraph/Paragraph';
import Signature from '../FormElements/Signature/Signature';
import RadioSelection from '../FormElements/RadioSelection/RadioSelection';
import FileUpload from '../FormElements/FileUpload/FileUpload';
import DatePickerComponent from '../FormElements/DatePicker/DatePickerComponent';
import CheckboxComponent from '../FormElements/Checkbox/CheckboxComponent';
import HorizontalContainer from '../FormElements/HorizontalContainer/HorizontalContainer';
import LogoComponent from '../FormElements/Logo/LogoComponent';
import { useParams } from "react-router-dom";
import { fetchClientDetailData } from '../../utils/formUtils';
import BackgroundColorContext from '../../context/BackgroundColorContext';





const FormElement = ({ element, dragHandle , onClick, setSelectedElementId, border }) => {
  // const { attributes, listeners, transform, } = useDraggable({ id: element.id });
  const [logoScr,setLogoSrc]=useState();
  const { backgroundColor, setBackgroundColor } = useContext(BackgroundColorContext);
  const { clientId } = useParams();
  const clientDetail= async()=>{
     const reponse= await fetchClientDetailData(clientId)
     setLogoSrc(reponse.LogoImageData);
     setBackgroundColor(reponse.BackgroundColour);

     const styleElement = document.createElement('style');
     styleElement.innerHTML = `.hh-heading { background-color: ${reponse.BackgroundColour} !important; }`;
     document.head.appendChild(styleElement);


     return reponse;
  }
useEffect(()=>{
  clientDetail();
},[])
  const renderElement = () => {
    switch (element.type) {
      case 'heading':
        return <Heading {...element.props} elementId={element.id} border={border}/>;
      case 'textField':
        return <TextField {...element.props} elementId={element.id} border={border} />;
      case 'paragraph':
        return <Paragraph {...element.props}  elementId={element.id}  border={border} />;
      case 'signature':
        return <Signature {...element.props} elementId={element.id} border={border} />;
      case 'radioSelection':
        return <RadioSelection {...element.props} elementId={element.id} onChange={() => {}} border={border}/>;
      case 'fileUpload':
        return <FileUpload {...element.props} elementId={element.id} border={border} />;
      case 'datePicker':
        return <DatePickerComponent {...element.props} elementId={element.id} border={border} />;
      case 'checkBox':
        return <CheckboxComponent {...element.props} elementId={element.id}border={border}/>;
      case 'horizontalContainer':
        return <HorizontalContainer {...element.props} dragHandle={dragHandle} elementId={element.id} items={element.items} setSelectedElementId={setSelectedElementId} border={border}/>;
      case 'logo':
        return <LogoComponent {...element.props} base64Src={logoScr} elementId={element.id}/>;
      default:
        return <div>Unknown element type</div>;
    }
  };

  return (
    <div>
      {renderElement()}
    </div>
  );
};

FormElement.propTypes = {
  element: PropTypes.object.isRequired,
};

export default FormElement;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import FormBuilder from "../components/FormBuilder/FormBuilder";
import FormPreview from "../components/FormPreview";
import { v4 as uuidv4 } from "uuid";
import {
  Button,
  Modal,
  Box,
  Grid,
  IconButton,
  Typography,
  Link,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  useTheme,
  Autocomplete,
  Alert,
  Snackbar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
// import data from '../components/FormBuilder/data';
import CloseIcon from "@mui/icons-material/Close";
import { ACTION } from "../utils/commonConstant";
import { fetchSubcontractorTypeData } from "../utils/formUtils";

const FormEditorPage = () => {
  //const defaultValue = [{"id":"37621acf-0b67-46b8-ae4a-51f510f1477d","type":"heading","props":{"text":"<p>test</p>","level":"2","color":"blue","backgroundColor":"yellow","height":"40px","width":"400px","margin":"2px"}},{"id":"4b7905b8-d0c0-44dd-bb72-ff205465b329","type":"textField","props":{"label":"Text Fieldd","color":"primary","width":200,"height":40}},{"id":"b2c9d154-d075-43cf-bf69-08bee3288ba9","type":"textField","props":{"label":"Text Field","color":"primary","width":200,"height":40}},{"id":"6ba4724d-3a3b-4991-8f20-d8d670f2b747","type":"heading","props":{"text":"<p>heading</p>","level":"2","color":"blue","backgroundColor":"yellow","height":"40px","width":"400px","margin":"px"}},{"id":"95e8cebe-d191-40d2-a3a6-86c355fed391","type":"heading","props":{"text":"<p>Heading</p>","level":"2","color":"blue","backgroundColor":"yellow","height":"40px","width":"400px","margin":"2px"}},{"id":"4e8bb966-fc31-483f-bf59-2edb987e18bb","type":"paragraph","props":{"text":"","level":"2","color":"blue","backgroundColor":"yellow","height":"40px","width":"400px","padding":"2px","fontSize":"12px"}}];
  const [elements, setElements] = useState([]);
  const [openPreview, setOpenPreview] = useState(false);
  const { clientId, action, contractVersionId } = useParams();
  const userId = localStorage.getItem("UserId");
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const handleOpenPreview = () => {
    if (elements.length === 0) {
      toast.error("Please add elements in form !!");
      return;
    }
    setOpenPreview(true);
  };
  const handleModelDialogClose = () => {
    setEditDialogOpen(false);
  };
  const handleClosePreview = () => setOpenPreview(false);
  const [contractId, setContractId] = useState(contractVersionId ?? null);
  const [hhContract, setHHContract] = useState({
    versionname: "",
    subcontractorType: "",
  });
  const [errors, setErrors] = useState({
    versionname: "",
    subcontractorType: "",
  });
  const [optionSubcontractorType, setOptionSubcontractorType] = useState([]);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const fetchsubcontractorType = async () => {
    const data = await fetchSubcontractorTypeData(clientId);
    setOptionSubcontractorType(data);
  };
  const handleAutocompleteChange = (event, newValue) => {
    setErrors({ ...errors,subcontractorType: "" });
    setSelectedOption(newValue);
    if (selectedOption) {
      setHHContract({
        ...hhContract,
        subcontractorType: selectedOption.Id ?? "",
      });
    }
  };
  // Function to handle save action
  const handleSave = async () => {
    if (elements.length === 0) {
      toast.error("Please add elements in form !!");
      return;
    }
    setEditDialogOpen(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };
  const fetchForm = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/HHContract/GetActiveHHContractstByClientId?clientId=${clientId}`,
        {
          headers: { "Content-Type": "application/json" },
          method: "GET",
          // body: JSON.stringify({
          //   "id": clientId
          // })
        }
      );
      // Handle response
      if (res.ok) {
        let json = await res.json();
        console.log(json, "jsondata");
        setElements(json.MetaData);
        setContractId(json.Id);
        setHHContract((prevHHContract) => ({
          ...prevHHContract,
          versionname: json.VersionName,
        }));
        setSelectedOption({
          Id: json.SubcontractorTypeId,
          Name: json.SubcontractorType,
        });
        console.log("Form saved successfully!");
      } else {
        setElements([]);
        // Handle error response
        console.error("Failed to save form:", await res.text());
      }
    } catch (error) {
      // Handle network errors
      console.error("Error while saving form:", error);
    }
  };

  const fetchEditForm = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/HHContract/GetHHContractsById?Id=${contractId}`,
        {
          headers: { "Content-Type": "application/json" },
          method: "GET",
        }
      );
      // Handle response
      if (res.ok) {
        let json = await res.json();
        console.log(json, "jsondata");
        setElements(json.MetaData);
        setContractId(json.Id);
        setHHContract((prevHHContract) => ({
          ...prevHHContract,
          versionname: json.VersionName,
        }));
        setSelectedOption({
          Id: json.SubcontractorTypeId,
          Name: json.SubcontractorType,
        });
        console.log("Form saved successfully!");
      } else {
        // Handle error response
        console.error("Failed to save form:", await res.text());
      }
    } catch (error) {
      // Handle network errors
      console.error("Error while saving form:", error);
    }
  };
  const handleRedirectVersion = () => {
    navigate(`/${clientId}`);
  };
  useEffect(() => {
    if (ACTION.CREATE === action) {
      setContractId(null);
    } else if (ACTION.EDIT === action) {
      fetchEditForm();
      setOpenSnackbar(true)
    } else {
      fetchForm();
    }
    fetchsubcontractorType();
  }, []);
  useEffect(() => {
    if (selectedOption) {
      setHHContract({
        ...hhContract,
        subcontractorType: selectedOption.Id ?? "",
      });
    }
    console.log(selectedOption, "selectedOption");
  }, [selectedOption]);

  // Styles for the modal
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
    maxHeight: "90vh",
  };

  if (!clientId) {
    return null;
  }
  const handleInputChange = (event) => {
    console.log(event.target);
    const { name, value } = event.target;
    setHHContract({ ...hhContract, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const handleEditSave = async () => {
    let formValid = true;
    const newErrors = { ...errors };
    // Basic validation
    if (!hhContract.versionname) {
      newErrors.versionname = "Version Name is required";
      formValid = false;
    }
    if (!hhContract.subcontractorType) {
      newErrors.subcontractorType = "Subcontractor Type is required";
      formValid = false;
    }
    if (!formValid) {
      setErrors(newErrors);
      return;
    }
    setEditDialogOpen(false);
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/HHContract/Create`,
        {
          headers: { "Content-Type": "application/json" },
          method: "POST",
          body: JSON.stringify({
            clientId: clientId,
            versionName: hhContract.versionname,
            metaData: elements,
            id: contractId,
            subcontractorType: hhContract.subcontractorType,
          }),
        }
      );

      if (res.ok) {
        toast.success("Form saved successfully!");
        console.log("Form saved successfully!");
      } else {
        console.error("Failed to save form:", await res.text());
        toast.error("Failed to save form:", await res.text());
      }
    } catch (error) {
      console.error("Error while saving form:", error);
      toast.error("Error while saving form:", error);
    }
  };
  return (
    <Box sx={{ padding: 2 }}>
       <Snackbar open={openSnackbar}  onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
         {`This is  Edit for Version Name is ${hhContract.versionname}`}
        </Alert>
      </Snackbar>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ marginBottom: 3 }}
      >
        <Grid item xs={12} sm="auto" display="flex" justifyContent="center">
          <Link href={`/${clientId}`}>
            <img
              src="https://portal.hardhats.co.uk/img/Hardhats-Logo.png"
              alt="HardHats Logo"
              style={{ width: "80px", marginRight: "20px" }}
            />
          </Link>
        </Grid>
        <Grid item xs={12} sm="auto">
          <h2 style={{ textAlign: "center" }}>Contract for Services</h2>
        </Grid>
      </Grid>
      <Grid container justifyContent="right" spacing={2}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenPreview}
          >
            Preview Form
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleSave}>
            {action === ACTION.EDIT ? "Update" : "Save"}
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRedirectVersion}
          >
            Home
          </Button>
        </Grid>
      </Grid>

      <FormBuilder elements={elements} setElements={setElements} />

      {/* Modal for form preview */}
      <Modal
        open={openPreview}
        onClose={handleClosePreview}
        aria-labelledby="form-preview-title"
        aria-describedby="form-preview-description"
        sx={{backgroundColor: '#CCCCCC',}}
      >
        <Box sx={modalStyle}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              id="form-preview-title"
              variant="h4"
              component="h3"
              align="center"
              flexGrow={1}
            >
              Form Preview
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleClosePreview}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          {/* <h2 id="form-preview-title" justifyContent='center'>Form Preview</h2> */}
          <FormPreview elements={elements} />
        </Box>
      </Modal>
      {/* model popup for contract version */}
      <Dialog
        open={editDialogOpen}
        onClose={handleModelDialogClose}
        maxWidth="md"
        fullWidth={fullScreen}
      >
        <DialogTitle>
          {ACTION.CREATE === action
            ? "Create Contract Version"
            : "Edit Contract Version"}
        </DialogTitle>
        <DialogContent sx={{ width: fullScreen ? "100%" : "600px" }}>
          <TextField
            margin="dense"
            label="Version Name"
            variant="outlined"
            name="versionname"
            value={hhContract.versionname}
            type="text"
            fullWidth
            onChange={handleInputChange}
            error={!!errors.versionname}
            helperText={errors.versionname}
          />
          <Autocomplete
            options={optionSubcontractorType}
            value={selectedOption}
            getOptionLabel={(option) => option.Name}
            // getOptionSelected={(option, value) => option.Id === value.Id}
            isOptionEqualToValue={(option, value) => option.valueOf  === value.valueOf }
            onChange={(event, newValue) =>
              handleAutocompleteChange(event, newValue)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Subcontractor Type"
                margin="dense"
                fullWidth
                error={!!errors.subcontractorType}
                helperText={errors.subcontractorType || ""}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModelDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEditSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default FormEditorPage;

import { useParams, useNavigate } from "react-router-dom";
import React, { useState, useCallback, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import FormElement from "../components/FormBuilder/FormElement";
import { Grid, Button, Paper, Box } from "@mui/material";
import { connect } from "react-redux";
import { FormContext, FormProvider } from "../FormContext";
import { useDispatch, useSelector } from "react-redux";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

import { formElements } from "../store/formSlice";

import {
  DndContext,
  closestCenter,
  closestCorners,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  useDraggable,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import toast from "react-hot-toast";

const FormDownloadPage = (props) => {
  const navigate = useNavigate();
  const { clientId } = useParams();
  const { documentType } = useParams();
  const { contractVersionId } = useParams();

  const formElementValues = useSelector(formElements);
  const [elements, setElements] = useState([]);
  const ToCaptureRef = React.useRef();
 // const [newTab,setNewTab]= useState(true);


  // const handleGeneratePdf = async () => {

  //   const element = ToCaptureRef.current;
  //   const canvas = await html2canvas(element, {
  //     scale: 2, // You can reduce this value to scale down the canvas
  //   });
  //   const data = canvas.toDataURL("image/jpeg", 0.5);
  //   console.log(data,'DataImage')
  //   const pdf = new jsPDF("p", "mm", "a4");
  //   const imgProperties = pdf.getImageProperties(data);
  //   const pdfWidth = pdf.internal.pageSize.getWidth();
  //   const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

  //   let position = 0;
  //   let remainingHeight = pdfHeight;

  //   while (remainingHeight > 0) {
  //     pdf.addImage(data, "JPEG", 0, position, pdfWidth, pdfHeight, undefined, 'FAST'); // Using JPEG and compression
  //     remainingHeight -= pdf.internal.pageSize.getHeight();
  //     position -= pdf.internal.pageSize.getHeight();
  //     if (remainingHeight > 0) {
  //       pdf.addPage();
  //     }
  //   }

  //   // Generate unique name for the PDF
  //   const fileUniqueName = `HHContractForm_${uuidv4()}.pdf`; // Example using UUIDv4
  //   console.log(fileUniqueName,'FileName')
  //   // Save the PDF directly in the browser
  //   pdf.save(fileUniqueName);
  //   //setNewTab(false);
  // };

  const fetchFormData = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/HHContract/GetHHContractsById?id=${contractVersionId}`,
        {
          headers: { "Content-Type": "application/json" },
          method: "GET",
          // body: JSON.stringify({
          //   "id": clientId
          // })
        }
      );

      if (res.ok) {
        const json = await res.json();
        setElements(json.MetaData);
        console.log("Form elements fetched successfully:", json.MetaData);
        return json.MetaData;
      } else {
        console.error("Failed to fetch form elements:", await res.text());
      }
    } catch (error) {
      console.error("Error while fetching form elements:", error);
    }
  };

  useEffect(() => {
    fetchFormData();
      //  setTimeout(() => {
      //    handleGeneratePdf();
      //  }, 2000);
  }, []);

  // useEffect(() => {
  //   if(newTab==false){
  //       window.close();
  //   }
   
  // }, [newTab]);

  return (
    <FormProvider>
    {/* <Box sx={{ display: "flex", justifyContent: "center", marginBottom: 2 ,marginTop:2}}>
    <Button
      variant="contained"
    color="success"

   onClick={handleGeneratePdf}
   >
     Download PDF
   </Button>
        </Box>  */}
    <Box position="relative" minHeight="100vh"sx={{
        backgroundColor: '#CCCCCC',
      }}>
      <Grid container justifyContent="center" ref={ToCaptureRef}>
        <Grid item xs={12} md={8}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Paper elevation={3} sx={{ padding: 2, minHeight: "700px" }}>
                    <Grid container spacing={2}>
                     {elements.map((element, index) => (
                        <Grid
                          xs={12}
                          item
                          key={element.id}
                          // style={
                          //   element.type === 'signature' || element.type === 'fileUpload'
                          //     ? { pageBreakInside: 'avoid' }
                          //     : {}
                          // }
                          sx={{
                            ...(element.type === 'signature' || element.type === 'fileUpload' ? { pageBreakInside: 'avoid' } : {}),
                            ...(element.type === 'logo' && {
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }),
                          }}
                        >
                            <FormElement element={element} dragHandle={false} />
                        </Grid>
                      ))}
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
        </Grid>
      </Grid>
    </Box>
  </FormProvider>

  );
};

// DraggableElement component
// const DraggableElement = ({ id, index, children }) => {
//   const {
//     attributes,
//     listeners,
//     setNodeRef,
//     transform,
//     transition,
//     isDragging,
//   } = useSortable({ id });

//   const style = {
//     transition,
//     transform: CSS.Transform.toString(transform),
//     position: "relative",
//   };

//   return (
//     <div ref={setNodeRef} style={style}>
//       <div>{children}</div>
//     </div>
//   );
// };

export default FormDownloadPage;

import React from 'react';
import PropTypes from 'prop-types';
import { DropzoneArea } from 'mui-file-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { updateElement } from '../../../store/formSlice';
import { Box } from '@mui/material';

const FileUpload = (props) => {
  const dispatch = useDispatch();


  async function getS3Url(fileName) {
    try {
      const res = await fetch(`https://s5a26rvza4jr333q7wugsfkega0gurkl.lambda-url.eu-west-2.on.aws`, {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify({
          "filename": fileName,
        })
      })
      const resultURL = await res.json();
      return resultURL;
    } catch (err) {
      console.log("Some Error occured while fetching the s3 URL's", err);
    }

  }


  const handleFileUpload = async (acceptedFiles) => {

    if (acceptedFiles.length == 0) return;
    // Access the uploaded files (array of File objects)
    const file = acceptedFiles[0]; // Assuming you want the first file

    const { uploadUrl, downloadUrl } = await getS3Url(file.name);
    const formData = new FormData();
    formData.append('file', file);
    const resUpload = await fetch(uploadUrl, {
      method: "PUT",
      headers: { "Content-Type": file.type },
      body: file
    });

    if (resUpload.ok) {
    console.log("Image uploaded successfully to S3!");
      // Use downloadUrl to update editor content or display image (optional)
    } else {
      console.error("Error uploading image:", resUpload.statusText);
    }



    // Update the element in the form state
    const updatedElement = {
      id: props.elementId,
      element: {
        ...props,
        value: downloadUrl, // Update value with the uploaded file object
      },
    };

    dispatch(updateElement(updatedElement));
  };

  const containerStyle = {
    width: props.width || '100%',
    height: props.height || 'auto',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 600px)': {
      width: '100%',
      height: 'auto',
    },
    '@media (min-width: 600px)': {
      width: props.width || '100%',
      height: props.height || 'auto',
    },
  };

  const dropzoneStyle = {
    flex: '1',
    height: '100%',
    width: '100%',
  };

  return (
    <Box
    sx={{
      containerStyle
    }}
  >
    <DropzoneArea
      acceptedFiles={['image/*']}
      dropzoneText={props.label || 'Drag and drop a file here or click'}
      onChange={handleFileUpload} // Pass handleFileUpload as onChange handler
      fullWidth
      style={{dropzoneStyle }}
      id={props.elementId}
    />
  </Box>
  );
};

FileUpload.propTypes = {
  label: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  elementId: PropTypes.string.isRequired, // Required for state management
  onFileUpload: PropTypes.func, // Optional, for custom handling if needed
};

export default FileUpload;

import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import FormElement from "../components/FormBuilder/FormElement";
import { Grid, Paper, Box } from "@mui/material";
import { FormProvider } from "../FormContext";
import DraggableElement from './DraggableElement';
import {
  DndContext,
  closestCenter,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import SignatureFileFillElement from '../components/FormFillDownloadElements/SignatureFileFillElement';
import RadioSelectionFillElement from '../components/FormFillDownloadElements/RadioSelectionFillElement';
import CheckboxFillElement from "../components/FormFillDownloadElements/CheckboxFillElement";
import HorizontalContainerFillElement from '../components/FormFillDownloadElements/HorizontalContainerFillElement';
import {fetchFormFillData} from '../api/contractVersionService'

const FormFillDownloadPage = () => {
  
const { fileId } = useParams();
const [elements, setElements] = useState([]);
const ToCaptureRef = React.useRef();
async function fetchData() {
    const formData= await fetchFormFillData(fileId);
    if (!formData) return;
    setElements(formData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <FormProvider>
      <Box position="relative" minHeight="100vh" sx={{mb:2 ,mt:2, backgroundColor: '#CCCCCC',}}>
        <Grid container justifyContent="center" ref={ToCaptureRef}>
          <Grid item xs={12} md={8}>
            <DndContext autoScroll={false} collisionDetection={closestCenter}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Paper elevation={3} sx={{ padding: 2, minHeight: "700px" }}>
                      <Grid container spacing={2}>
                        <SortableContext
                          items={elements}
                          strategy={verticalListSortingStrategy}
                        >
                       {elements.map((element, index) => (
                          <Grid
                            xs={12}
                            item
                            key={element.id}
                            // style={
                            //   element.type === 'signature' || element.type === 'fileUpload'
                            //     ? { pageBreakInside: 'avoid' }
                            //     : {}
                            // }
                            sx={{
                              ...(element.type === 'signature' || element.type === 'fileUpload' ? { pageBreakInside: 'avoid' } : {}),
                              ...(element.type === 'logo' && {
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }),
                            }}
                          >
                            <DraggableElement id={element.id} index={index}>
                              
                            <Box width="100%">
                                  {element.type === 'signature' || element.type === 'fileUpload' ? (
                                    <SignatureFileFillElement element={element}/>
                                  ) : 
                                  element.type === 'radioSelection' ? (
                                    <RadioSelectionFillElement element={element}/>
                                  ) : 
                                  element.type === 'checkBox' ? (
                                    <CheckboxFillElement element={element}/>
                                  ) : 
                                 
                                  element.type === 'horizontalContainer' ? (
                                    <HorizontalContainerFillElement element={element}/>
                                  ) : 
                                  
                                  (
                                    <FormElement element={element} dragHandle={false} />
                                  )}
                                </Box>
                            </DraggableElement>
                          </Grid>
                        ))}

                        </SortableContext>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Box>
            </DndContext>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  );
};

export default FormFillDownloadPage;

import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

import { useDispatch, useSelector } from 'react-redux';
import { updateElement } from '../../../store/formSlice';

const CustomTextField = (props) => {
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const updatedValue = event.target.value; // Get the new value from the event

    // Update the element in the form state
    const updatedElement = {
      id: props.elementId,
      element: {
        ...props,
        value: updatedValue,
      },
    };
    dispatch(updateElement(updatedElement));
  };

  return (
    <TextField
      label={props.label}
      value={props.value}
      onChange={handleChange} // Pass handleChange as the onChange handler
      fullWidth
      color={props.color}
      required={props.required}
      error={props.error}
      helperText={props.helperText}
      sx={{
        width: {
          xs: '100%',   // Full width on extra-small screens
          sm: '75%',    // 75% width on small screens
          md: '50%',    // 50% width on medium screens
          lg: props.width,  // Use provided width on large screens
          xl: props.width   // Use provided width on extra-large screens
        },
        height: {
          xs: 'auto',   // Auto height on extra-small screens
          sm: 'auto',   // Auto height on small screens
          md: 'auto',   // Auto height on medium screens
          lg: props.height,  // Use provided height on large screens
          xl: props.height   // Use provided height on extra-large screens
        }
      }}
    />
  );
};

CustomTextField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired, // Required as we're using it now
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

CustomTextField.defaultProps = {
  color: 'primary',
  width: 200,
  height: 40,
};

export default CustomTextField;

import React from 'react';
import { IconButton, Tooltip, Popover, Button, TextField } from '@mui/material';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import { HexColorPicker } from 'react-colorful';

export const MenuButtonBackgroundColor = ({ editor }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [color, setColor] = React.useState('#ffffff');

  if (!editor) {
    return null;
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorChange = (color) => {
    setColor(color);
  };

  const handleHexInputChange = (event) => {
    setColor(event.target.value);
  };

  const handleApplyColor = () => {
    if (color) {
      editor.chain().focus().setBackgroundColor(color).run();
    } else {
      editor.chain().focus().unsetBackgroundColor().run();
    }
    handleClose();
  };

  const handleUnsetColor = () => {
    editor.chain().focus().unsetBackgroundColor().run();
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'color-popover' : undefined;

  return (
    <>
      <Tooltip title="Background Color">
        <IconButton size="small" onClick={handleClick}>
          <FormatColorFillIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div style={{ padding: 16 }}>
          <HexColorPicker color={color} onChange={handleColorChange} />
          <TextField
            value={color}
            onChange={handleHexInputChange}
            label="Hex Code"
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 8 }}>
            <Button onClick={handleUnsetColor} color="primary">
              Reset
            </Button>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleApplyColor} color="primary">
              OK
            </Button>
          </div>
        </div>
      </Popover>
    </>
  );
};
